import axios, { AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import Cookies from "js-cookie";
import ApiError from "./error";
import { setContext } from "@sentry/react";

const instance: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  withCredentials: true,
});

instance.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const accessToken = localStorage.getItem("access_token");

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.data &&
      error.response.data.message === "Access Token이 유효하지 않습니다."
    ) {
      try {
        await instance.post("/auth");

        const newAccessToken = Cookies.get("access_token");

        if (newAccessToken) {
          localStorage.setItem("access_token", newAccessToken);

          // 새로운 토큰으로 원래 요청을 다시 시도
          error.config.headers["Authorization"] = `Bearer ${newAccessToken}`;
          return instance(error.config);
        }
      } catch (tokenRefreshError) {
        return Promise.reject(
          new ApiError(tokenRefreshError, tokenRefreshError.response.data.message),
        );
      }
    }

    setContext("API Request Detail", {
      method: error.config.method,
      url: error.config.url,
      params: error.config.params,
      headers: error.config.headers,
    });

    setContext("API Response Detail", {
      status: error.response.status,
      data: error.response.data,
    });

    return Promise.reject(new ApiError(error, error.response?.data?.message));
  },
);

export default instance;
