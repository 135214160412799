import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { Router } from "./router.tsx";

const App = () => {
  // useEffect(() => {
  //   if (import.meta.env.PROD) {
  //     hotjar.initialize({
  //       id: Number(`${import.meta.env.VITE_HJID}`),
  //       sv: Number(`${import.meta.env.VITE_HJSV}`),
  //     });
  //   }
  // }, []);

  return (
    <>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </>
  );
};

export default App;
