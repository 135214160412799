import SpinnerComponent from "@/components/guides/spinner/Spinner";
import { HTTP_STATUS } from "@/http_status";
import { colors } from "@/styles/color";
import instance from "@/utils/axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import styled from "styled-components";
import { useEffect } from "react";
import useTextareaStore from "@/store/useTextareaStore";

const LoadingAuthenticationPage: React.FC = () => {
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const code = params.get("code");
  const platform = params.get("state") === "flase" ? "naver" : "google";

  const { createProject, project } = useTextareaStore();

  const handleLogin = async (code: string) => {
    try {
      // TODO: For production, Change axios to axios instance
      // const { status: userStatus, data: userData } = await axios.post(
      // "/api/user/signin/code",
      const { status: userStatus, data: userData } = await instance.post(
        "/user/signin/code",
        {
          code: code,
          platform: platform,
        },
      );
      if (userStatus == HTTP_STATUS[200].CODE) {
        const accessToken = Cookies.get("access_token");
        localStorage.setItem("access_token", accessToken);

        localStorage.setItem("user", JSON.stringify(userData));

        userData.projectLists.forEach((projectId) => {
          if (!project[projectId]) {
            createProject({ projectId: projectId });
          }
        });

        if (userData.signinTimestamps.length === 0) {
          // New User
          navigate("/authentication/agreement");
        } else {
          // Existing User
          navigate("/");
          // const getIpResponse = await instance.post("/user/ip/check", {
          //   email: userData.email,
          // });

          // if (getIpResponse.data.length === 0) {
          //   navigate("/");
          // } else {
          //   navigate("/authentication/mobile?type=changed");
          // }
        }
      } else {
        alert("로그인을 다시 시도해주시기 바랍니다.");
        navigate("/authentication");
      }
    } catch (error) {
      alert("로그인을 다시 시도해주시기 바랍니다.");
      navigate("/authentication");
    }
  };

  useEffect(() => {
    if (code) {
      handleLogin(code);
    } else {
      alert("로그인을 다시 시도해주시기 바랍니다.");
      navigate("/authentication");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledLoadingAuthenticationPage>
      <SpinnerComponent />
    </StyledLoadingAuthenticationPage>
  );
};
export default LoadingAuthenticationPage;

const StyledLoadingAuthenticationPage = styled.div`
  background-color: ${colors.bg_con};
  min-height: 100vh;
  margin: 0;
`;
