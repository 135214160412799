import React, { useState } from "react";
import { colors } from "@/styles/color";
import styled from "styled-components";
import { boxShadow3, boxShadow } from "@/styles/shadow";
import CheckImg from "../../assets/icons/18px/check.tsx";
import Icon from "../../assets/icons/20px/info.svg";
import EnterCompanyModal from "./EnterCompanyModal.tsx";
import DimmedModalComponent from "../guides/modal/DimmedModal.tsx";

interface PlanModalProps {
  isOpened: boolean;
  closeModal: () => void;
  backAction: () => void;
  openPlanModal: () => void;
}

export default function PlanModal({
  isOpened,
  closeModal,
  backAction,
  openPlanModal,
}: PlanModalProps) {
  const [isCompanyInfoModalOpen, setIsCompanyInfoModalOpen] = useState(false);
  const [plan, setPlan] = useState<"Personal" | "Team">("Personal");

  const handlePersonalClick = () => {
    setPlan("Personal");
    setIsCompanyInfoModalOpen(true);
    closeModal();
  };

  const handleTeamClick = () => {
    setPlan("Team");
    setIsCompanyInfoModalOpen(true);
    closeModal();
  };

  const closeCompanyInfoModal = () => {
    setIsCompanyInfoModalOpen(false);
  };

  const backEnterCompanyModal = () => {
    setIsCompanyInfoModalOpen(() => false);
    openPlanModal();
  };

  return (
    <>
      <DimmedModalComponent
        isOpened={isOpened}
        closeModal={closeModal}
        backAction={backAction}
        content={
          <PlanModalContent>
            <PlanInfoWrap>
              <div className="card-wrap">
                <PersonalCard>
                  <h1>Personal</h1>
                  <section>
                    <div className="plan-detail">
                      <CheckImg color="red" />
                      <p>개인용 서비스</p>
                    </div>
                    <div className="plan-detail">
                      <CheckImg color="red" />
                      <p>쟁점 관련 자료 검색 및 분석</p>
                    </div>
                    <div className="plan-detail">
                      <CheckImg color="red" />
                      <p>인당 40k tokens 기본 제공</p>
                      <TooltipWrapper>
                        <img src={Icon} />
                        <TooltipLong>평균 40개 쟁점 AI 검색 및 분석</TooltipLong>
                      </TooltipWrapper>
                    </div>
                    <div className="plan-detail">
                      <CheckImg color="red" />
                      <p>저장공간 5GB 기본 제공</p>
                    </div>
                    <div className="plan-detail">
                      <CheckImg color="red" />
                      <p>실시간 고객 지원</p>
                    </div>
                  </section>

                  <article>
                    <p>
                      beta 버전 <span className="bold-date">3개월 무료 프로모션</span>이
                      진행 중입니다
                      <br /> 1분 내로 쟁점 검토를 완료해보세요.
                    </p>
                    <TooltipWrapper>
                      <img src={Icon} />
                      <Tooltip>2024년 09월 까지</Tooltip>
                    </TooltipWrapper>
                  </article>
                  <button onClick={handlePersonalClick}>시작하기</button>
                </PersonalCard>
                <TeamCard>
                  <h1>Team</h1>
                  <section>
                    <div className="plan-detail">
                      <CheckImg />
                      <p>팀 내 프로젝트 공유</p>
                    </div>
                    <div className="plan-detail">
                      <CheckImg />
                      <p>쟁점 관련 자료 검색 및 분석</p>
                    </div>
                    <div className="plan-detail">
                      <CheckImg />
                      <p>인당 40k tokens 기본 제공</p>
                      <TooltipWrapper>
                        <img src={Icon} />
                        <TooltipLong>평균 50개 쟁점 AI 검색 및 분석</TooltipLong>
                      </TooltipWrapper>
                    </div>
                    <div className="plan-detail">
                      <CheckImg />
                      <p>저장공간 5GB 기본 제공</p>
                    </div>
                    <div className="plan-detail">
                      <CheckImg />
                      <p>실시간 고객 지원</p>
                    </div>
                  </section>

                  <article>
                    <p>
                      beta 버전 <span className="bold-date">3개월 무료 프로모션</span>이
                      진행 중입니다
                      <br /> 1분 내로 쟁점 검토를 완료해보세요.
                    </p>
                    <TooltipWrapper>
                      <img src={Icon} />
                      <Tooltip>2024년 09월 까지</Tooltip>
                    </TooltipWrapper>
                  </article>
                  <button onClick={handleTeamClick}>시작하기</button>
                </TeamCard>
              </div>
            </PlanInfoWrap>
          </PlanModalContent>
        }
      />

      {isCompanyInfoModalOpen && (
        <EnterCompanyModal
          isOpened={isCompanyInfoModalOpen}
          closeModal={closeCompanyInfoModal}
          backAction={backEnterCompanyModal}
          openCompanyModal={() => setIsCompanyInfoModalOpen(true)}
          plan={plan}
        />
      )}
    </>
  );
}

const PlanModalContent = styled.div`
  width: 900px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 605px;
  z-index: 1;
`;

const PlanInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
  .card-wrap {
    display: flex;
  }
`;

const PersonalCard = styled.article`
  ${boxShadow}
  width: 340px;
  height: 500px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  border-top: 12px solid ${colors.secondary_main};
  margin-top: 20px;
  h1 {
    font-weight: 600;
    font-size: 25px;
    margin-top: 50px;
    margin-bottom: 31px;
    color: ${colors.secondary_main};
  }
  .plan-detail {
    width: 300px;
    display: flex;
    align-items: center;
    height: 19px;
    gap: 8px;
    margin-bottom: 16px;
  }
  article {
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid ${colors.secondary_main};
    border-radius: 12px;
    margin: 15px 8px;
    p {
      width: 226px;
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
      line-height: 15px;
    }
  }
  button {
    ${boxShadow3}
    border: none;
    color: ${colors.white};
    width: 300px;
    height: 47px;
    background-color: ${colors.secondary_main};
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  .bold-date {
    font-weight: 700;
  }
`;

const TeamCard = styled.article`
  ${boxShadow}
  width: 340px;
  height: 500px;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  border-top: 12px solid ${colors.primary_main};
  margin-top: 20px;
  h1 {
    font-weight: 600;
    font-size: 25px;
    margin-top: 50px;
    margin-bottom: 31px;
    color: ${colors.primary_main};
  }
  .plan-detail {
    width: 300px;
    display: flex;
    align-items: center;
    height: 19px;
    gap: 8px;
    margin-bottom: 16px;
  }
  article {
    width: 300px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid ${colors.primary_main};
    border-radius: 12px;
    margin: 15px 8px;
    p {
      width: 226px;
      font-size: 12px;
      font-weight: 400;
      white-space: nowrap;
      line-height: 15px;
    }
  }
  button {
    ${boxShadow3}
    border: none;
    color: ${colors.white};
    width: 300px;
    height: 47px;
    background-color: ${colors.primary_main};
    border-radius: 12px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
  }
  .bold-date {
    font-weight: 700;
  }
`;

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;

  img {
    width: 20px;
    height: 20px;
  }

  &:hover span {
    visibility: visible;

    opacity: 1;
  }
`;

const Tooltip = styled.span`
  visibility: hidden;
  white-space: nowrap;
  background-color: ${colors.neutral_g3};
  color: ${colors.white};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 9999;
  top: 125%;
  left: 10%;
  transform: translateX(0%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  padding: 8px;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10px;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${colors.neutral_g3} transparent;
  }
`;

const TooltipLong = styled.span`
  visibility: hidden;
  white-space: nowrap;
  background-color: ${colors.neutral_g3};
  color: ${colors.white};
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 125%;
  right: -110px;
  transform: translateX(30%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 14px;
  padding: 8px;

  &::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 10px;
    transform: translateX(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent ${colors.neutral_g3} transparent;
  }
`;
