import React from "react";
import styled from "styled-components";
import { ButtonComponentProps } from "@/types/components/buttonProps";
import { colors } from "@/styles/color";
import { title16 } from "@/styles/typography";

const ButtonComponent: React.FC<ButtonComponentProps> = (props: ButtonComponentProps) => {
  const action =
    props.action ??
    (() => {
      alert("무료 배포판에서 제공하지 않는 기능입니다.");
    });

  return (
    <StyledButtonComponent onClick={action} {...props}>
      {props.text}
    </StyledButtonComponent>
  );
};

const StyledButtonComponent = styled.button<ButtonComponentProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin: 0;
  // border: 0px;

  border: ${(props) =>
    props.bgBorderColor ? `1px solid ${props.bgBorderColor}` : `0px`};

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  border-radius: 12px;
  padding-top: 15px;
  padding-bottom: 14px;

  cursor: ${(props) => (props.isDisabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.isDisabled ? "none" : "auto")};

  ${(props) => props.fontSize || title16}

  color: ${(props) => props.$textcolor || colors.white};
  background-color: ${(props) => props.$bgcolor || colors.primary_main};

  opacity: ${(props) => (props.isDisabled ? 0.4 : 1.0)};

  &:hover {
    opacity: 0.8;
  }
`;

export default ButtonComponent;
