import { colors } from "@/styles/color";
import { body18 } from "@/styles/typography";
import { IconButtonComponentProps } from "@/types/components/buttonProps";
import { styled } from "styled-components";

const IconButtonComponent: React.FC<IconButtonComponentProps> = (
  props: IconButtonComponentProps,
) => {
  return (
    <StyledIconButtonComponent onClick={props.action}>
      <div className="social-icon">{props.icon}</div>
      <div className="social-text">{props.text}</div>
    </StyledIconButtonComponent>
  );
};

const StyledIconButtonComponent = styled.button`
  background-color: white;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${body18}
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 999px;
  border: 1px ${colors.neutral_g2} solid;
  margin-bottom: 8px;
  padding: 6px;

  .social-text {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 51px;
  }

  .social-icon {
    display: flex;
    align-items: center;
  }

  &:hover {
    border: 1px ${colors.neutral_g4} solid;
  }
`;

export default IconButtonComponent;
