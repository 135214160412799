import styled from "styled-components";
import { DataCardProps, KeywordListProps } from "./ParseData";
import { colors } from "@/styles/color";
import { body16, body16_500 } from "@/styles/typography";

// const SimilarCases: React.FC<SimilarCasesProps> = ({ cases }) => {
//   const formatText = (text: string) => {
//     const regex = /\(([^)]+)\)/;
//     const parts = text.split(regex);

//     return parts.map((part, index) => {
//       if (index % 2 === 1) {
//         return (
//           <span key={index} style={{ color: colors.neutral_g4 }}>{`(${part})`}</span>
//         );
//       }
//       return part;
//     });
//   };
//   return (
//     <div>
//       {cases.map((item, idx) => (
//         <StyledSimilarCase key={idx}>
//           {/* <div className="docId">
//         링크 고유 번호: {item.linkId}
//       </div> */}
//           <div className="content">{formatText(item.titleAndCaseNumber)}</div>
//         </StyledSimilarCase>
//       ))}
//     </div>
//   );
// };

const KeywordList: React.FC<KeywordListProps> = ({ keywords }) => (
  <StyledKeywordContainer>
    {keywords.map((keyword, idx) => (
      <div key={idx}>{keyword}</div>
    ))}
  </StyledKeywordContainer>
);

const DataModalContentComponent: React.FC<DataCardProps> = ({ data }) => (
  <StyledDataModalContent>
    <div className="header">
      <div className="tags">
        <div className="caseType">{data.category}</div>
        {data.status && <div className="subHeading">{data.status}</div>}
      </div>
      <div className="main-title">{data.title}</div>
      <div className="captions">
        {/* <div className="linkId">링크 고유 번호: {data.linkId}</div>
      <div>|</div> */}
        <div className="caseNum">사건번호: {data.caseNumber}</div>
        <div>|</div>
        <div className="date">생산일자: {data.createdDate}</div>
      </div>
      <KeywordList keywords={data.keyword} />
    </div>
    <div className="main">
      <div className="labelChip">요지</div>
      <div className="gist">{data.caption}</div>
      <div className="labelChip">회신</div>
      <div className="response">{data.reply}</div>
      <div className="labelChip detail">상세내용</div>
      <div
        className="innerHTML"
        dangerouslySetInnerHTML={{ __html: data.detailedContent }}
      />
    </div>
    {/* <div className="main"> */}
    {/* <div className="labelChip">유사사건</div> */}
    {/* <SimilarCases cases={data.similarCases} /> */}
    {/* </div> */}
  </StyledDataModalContent>
);
export default DataModalContentComponent;

// const StyledSimilarCase = styled.div`
//   border-bottom: 1px solid ${colors.neutral_g2};
//   padding: 10px 0;
//   .content:before {
//     content: "";
//     width: 0;
//     height: 0;
//     border-top: 5px solid transparent;
//     border-bottom: 5px solid transparent;
//     border-left: 6px solid ${colors.neutral_g4};
//     display: inline-block;
//     margin-right: 5px;
//   }

//   .docId {
//     font-size: ${body14};
//   }
// `;

const StyledKeywordContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 10px;

  div {
    background-color: ${colors.secondary_sub};
    color: black;
    padding: 5px 14px;
    border-radius: 18px;
    ${body16_500}
  }
`;
const StyledDataModalContent = styled.div`
  background-color: white;
  line-height: 1.5;
  .header {
    margin: 35px 40px 15px 40px;
  }
  .tags {
    display: flex;
    width: 500px;
    gap: 6px;
    align-items: center;
    font-size: ${body16};
    margin-bottom: 15px;
    .caseType {
      border-radius: 8px;
      width: fit-content;
      padding: 3.5px 9px;
      color: ${colors.primary_main};
      background-color: ${colors.primary_sub};
    }
    .subHeading {
      border-radius: 8px;
      width: fit-content;
      padding: 3.5px 9px;
      color: ${colors.secondary_main};
      background-color: ${colors.secondary_sub};
    }
  }

  .main {
    background-color: ${colors.bg_con};
  }

  .main-title {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;
  }
  .captions {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: ${body16_500};
    color: ${colors.primary_main};
    margin-bottom: 15px;
  }
  .main {
    padding: 26px 40px;
  }
  .main > div:nth-child(2n) {
    margin: 0px 10px 15px 10px;
    /* padding: 6px 10px; */
  }
  .labelChip {
    border-radius: 4px;
    width: fit-content;
    color: ${colors.primary_main};
    ${body16_500}
    padding: 6px 8px;
    margin-bottom: 17px;
  }

  .detail {
    margin-bottom: 0px;
  }
  /* 상세내용 */
  .innerHTML {
    margin: 0 0 60px 0;
    color: black;
    /* padding: 0 20px 0 20px; */
    border-radius: 12px;
    font-size: 16px;
    font-weight: 400;

    .sebeop_t {
      width: 732px;
    }

    /* 관련 참고자료 */
    /* p:nth-child(2) {
      display: none;
    }

    /* 그외 헤딩 */
    /* p:not(:nth-child(2)):not(:nth-child(1)):not(:nth-child(3)) {
      margin-left: 0px !important;
      padding: 0 10px;
    }
    p:nth-child(4) .bold {
      margin-top: 0 !important;
      padding-top: 0 !important;
    }
    p:not(:nth-child(2)):not(:nth-child(1)):not(:nth-child(3)) .bold {
      padding-top: 10px;
      display: block !important;
      font-weight: bold;
      font-size: large;
      margin-top: 40px;
    } */

    img {
      margin-bottom: 20px;
    }

    a {
      text-decoration: none;
      color: black;
    }

    .before-bold {
      background-color: lightgray;
    }
    .highlight {
      border-left: 3px solid red;
      padding-left: 5px;
    }
  }
`;
