import React from "react";

const RightArrow: React.FC<{ color?: string; size?: number }> = (props: {
  color?: string;
}) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="btn">
        <path
          id="ico"
          d="M6.5 4L11.5 9L6.5 14"
          stroke={props.color || "#AAAAAA"}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default RightArrow;
