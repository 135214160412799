export const colors = {
  primary_main: "#3232C2",
  primary_sub: "#ECECFF",
  primary_third: "#3F3FD8",
  secondary_main: "#FF5252",
  secondary_sub: "#FFECEC",

  black: "#000000",
  neutral_g4: "#888888",
  neutral_g3: "#AAAAAA",
  neutral_g2: "#EEEEEE",
  neutral_g1: "#F4F4F4",
  white: "#FFFFFF",
  charcoal: "#222222",

  bg_top: "#2B2B57",
  bg_con: "#F7F7FD",
};
