import Close from "@/assets/icons/18px/Close";
import ModalPortal from "@/components/modal/ModalPortal";
import { ModalComponentProps } from "@/types/components/modalProps";
import { allowScroll, preventScroll } from "@/utils/modal";
import React, { useEffect } from "react";
import styled from "styled-components";

interface StyledModalWithCloseComponentProps {
  backgroundColor?: string;
  color?: string;
}

const DimmedModalWithCloseComponent: React.FC<
  ModalComponentProps & StyledModalWithCloseComponentProps
> = ({
  isOpened,
  closeModal,
  backAction,
  content,
  eraseCloseIcon,
  backgroundColor,
  color,
}: ModalComponentProps & StyledModalWithCloseComponentProps) => {
  useEffect(() => {
    const prevScrollY = preventScroll();
    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  if (!isOpened) return null;

  return (
    <ModalPortal>
      <StyledModalWithCloseComponent backgroundColor={backgroundColor} color={color}>
        <div className="modal">
          <div className="modal-content">
            {eraseCloseIcon ? null : backAction ? (
              <button className="back-action" onClick={backAction}>
                <Close />
              </button>
            ) : (
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            )}
            {content}
          </div>
        </div>
      </StyledModalWithCloseComponent>
    </ModalPortal>
  );
};

const StyledModalWithCloseComponent = styled.div<StyledModalWithCloseComponentProps>`
  .modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: ${({ backgroundColor }) => backgroundColor || "white"};
    box-shadow: 0px 10px 20px 0px rgba(17, 17, 136, 0.1);
    border-radius: 18px;
    position: relative;
    overflow: hidden;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 18px;
    cursor: pointer;
    font-size: 30px;
    color: ${({ color }) => color || "#888888"};
    &:hover {
      color: black;
    }
  }

  .back-action {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    img {
      width: 18px;
      height: 18px;
      filter: brightness(100%) invert(100%);
    }
  }
`;

export default DimmedModalWithCloseComponent;
