import { colors } from "@/styles/color";
import { useRef, useEffect, TextareaHTMLAttributes } from "react";
import styled from "styled-components";

const AutoResizeTextarea = ({
  value,
  onChange,
  ...props
}: TextareaHTMLAttributes<HTMLTextAreaElement>) => {
  const textareaRef = useRef(null);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  });

  return (
    <StyledAutoResizeTextarea
      ref={textareaRef}
      value={value}
      onChange={onChange}
      onInput={adjustTextareaHeight}
      {...props}
    />
  );
};

export default AutoResizeTextarea;

const StyledAutoResizeTextarea = styled.textarea`
  background-color: white;
  resize: none;
  overflow-y: hidden;

  width: 100%;
  border-radius: 12px;
  border: 1px solid ${colors.neutral_g2};

  &:focus {
    outline: 1px solid ${colors.primary_main};
  }
  &::placeholder {
    color: ${colors.neutral_g3};
  }

  padding: 20px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
