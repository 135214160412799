import { colors } from "@/styles/color";
import styled from "styled-components";
import TButton from "../guides/button/TButton";

interface TermsOfUseModalComponentProps {
  closeModal: () => void;
}
const TermsOfUseModalComponent: React.FC<TermsOfUseModalComponentProps> = ({
  closeModal,
}: TermsOfUseModalComponentProps) => {
  return (
    <StyledTermsOfUseModalComponent>
      <div className="title">서비스 이용약관</div>
      <div className="content">
        제 1 장 총칙
        <br />
        <br />
        제 1 조 (목적)
        <br />
        <br />
        본 약관은 ‘Tax Canvas’가 제공하는 서비스(제2조에서 정의합니다)의 이용과 관련하여
        ‘Tax Canvas’와 ‘사용자’(제2조에서 정의합니다) 간의 권리, 의무 및 책임사항,
        ‘사용자’의 ‘서비스’ 이용 절차에 관한 사항을 규정함을 목적으로 합니다.
        <br />
        <br />
        <br />
        <br />
        제 2 조 (용어의 정의)
        <br />
        <br />
        본 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <br />
        <br />
        1. 문서란 ‘Tax Canvas’가 제공하는 서비스의 기능, 운영 및 사용에 관하여 제품을
        통해서 제공하거나 ‘Tax Canvas’가 제공하는 매뉴얼, 지침서, 지도서 및 기타 사용자
        지침, 문서 및 자료를 의미합니다.
        <br />
        <br />
        2. 사용자란 ‘Tax Canvas’에 접속하여 본 약관에 동의하거나 이 약관을 승낙하고 ‘Tax
        Canvas’가 제공하는 서비스를 받는 자를 의미합니다.
        <br />
        <br />
        3. 서비스란 함은 ‘사용자'와의 문서에서 상세히 설명된 계약 내용에 따라 ‘Tax
        Canvas’가 가장 관련성 높은 세무 관련 예규, 판례, 법령을 제공하는 클라우딩 컴퓨팅
        서비스, 서비스형 소프트웨어, 온라인 연구 서비스뿐만 아니라 설치 소프트웨어를
        포함한 모든 제품을 의미합니다.
        <br />
        <br />
        4. 사용정보란 ① ‘Tax Canvas’ 소유 재산의 제공 및 운용과 관련된 통계 편집물 및 성능
        정보를 포함하여, ‘Tax Canvas’ 소유 지식재산의 사용과 관련하여 집계 후 익명처리된
        데이터 및 정보, ② 본 서비스에 대한 ‘사용자’의 접근 또는 이용으로부터 파생된 정보,
        데이터 또는 기타 내용을 의미하되, ‘사용자’의 데이터는 포함하지 아니합니다.
        <br />
        <br />
        5. 사용자의 데이터라 함은, 사용정보를 제외하고 본 서비스를 통해 ‘사용자’에 의하여
        또는 ‘사용자’를 대신하여 제출, 게시 또는 달리 전송되는 모든 형태의 매체의 정보,
        데이터 및 기타 내용을 의미합니다.
        <br />
        <br />
        6. 본 약관에서 사용하는 용어 중 본 조에서 정하지 아니한 사항은 관계 법령에서
        정하는 바에 따르며, 그 외에는 일반 관례에 따릅니다.
        <br />
        <br />
        <br />
        <br />
        제 3 조 (IP 소유권, 라이선스 및 배송)
        <br />
        <br />
        ‘Tax Canvas’는 IP 허가권자와 함께 본 약관 또는 별도 약정에 따라 모든 IP권리를
        보유합니다. 본 약관 또는 별도 약정에서 명시적으로 부여한 제한적인 권리 및
        라이선스를 제외하고, 본 약관의 어떠한 규정도 ‘사용자’ 또는 제3자에게 암묵적으로나,
        명시적으로 ‘Tax Canvas’에 대한 지식재산권이나 소유권, 이권 또는 기타 권리를
        부여하는 것으로 해석되지 않습니다.
        <br />
        <br />
        본 약관에 따라 ‘사용자’에게 설치하여 운용되는 소프트웨어 외에는 ‘Tax Canvas’는 본
        약관에 따라 ‘사용자’에게 ‘사용자’의 내부 업무 목적을 위해서만 ‘Tax Canvas’의
        서비스에 접근∙시청∙이용할 수 있는 비독점적이고, 재라이선스가 불가하며, 양도
        불가능한 권리를 부여합니다.
        <br />
        <br />
        문서 이용이 가능한 경우, 계약에 포함된 약관에 따라, ‘Tax Canvas’는 ‘사용자’에게
        ‘사용자’의 내부 업무 목적과 ‘Tax Canvas’의 서비스 사용과 관련된 용도로만 그러한
        문서를 사용할 수 있는 비독점적이고 재라이선스 불가하며, 양도 불가능한 라이선스를
        부여합니다.
        <br />
        <br />
        본 계약의 약관에 따라, ‘사용자’가 ‘Tax Canvas’에 설치된 소프트웨어에 대한
        라이선스를 구입하거나 구독하는 범위 내에서, ‘Tax Canvas’는 ‘사용자’의 내부 업무
        목적으로만 설치 및 사용할 수 있는 비독점적이고, 재라이선스 불가하며, 양도 불가능한
        권한을 ‘사용자’에게 부여합니다. 설치된 소프트웨어의 사본은 ① ‘Tax Canvas’의 배타적
        재산으로 남아 있고, ② 본 계약의 약관 규정이 적용되며, ③ 원본에 포함된 모든 저작권
        또는 기타 PI권리 고지를 포함하고 있어야 합니다. ‘사용자’는 그러한 설치
        소프트웨어를 목적 코드 형식으로만 사용할 수 있습니다.
        <br />
        <br />
        ‘사용자’는 본 약관 및 관련 법령에 따라, ‘Tax Canvas’ 및 그 직원, 도급업체가 본
        계약에 따른 서비스를 제공하기 위해 필요한 경우로 한정하여 ‘사용자’의 데이터를
        사용∙복사∙저장∙호스트∙전시∙전송 및 처리할 수 있는 비독점적 라이센스와 권리를 ‘Tax
        Canvas’에게 부여합니다. 관련 법령에 따라 필요한 경우 ‘Tax Canvas’는 ‘사용자’의
        데이터를 삭제하거나 비활성화할 수 있으며, 이러한 경우 해당 ‘사용자’에게 통지하기
        위해 통상적이고 합리적인 범위 내에서 최대한의 노력을 기울입니다.
        <br />
        <br />
        ‘Tax Canvas’는 ‘Tax Canvas‘의 합리적인 재량에 따라 서비스 및 모든 문서를
        전자적으로 또는 유형적 매체를 활용하여 전달합니다. ‘사용자’가 ‘Tax Canvas’의
        서비스 또는 문서를 다운로드 하거나 이에 접속하는 경우, 이는 ‘사용자’에게 본 약관에
        따른 서비스의 배송이 이뤄진 것으로 간주됩니다.
        <br />
        <br />
        <br />
        <br />
        제 4 조 (약관의 게시와 개정)
        <br />
        <br />
        본 약관의 내용은 ‘사용자’가 서비스의 가입 및 이용시 열람할 수 있으며, ‘Tax
        Canvas’는 본 약관의 내용을 ‘사용자’가 쉽게 열람할 수 있도록 서비스 홈페이지(이하
        "홈페이지"라 합니다) 또는 연결화면을 통하여 게시합니다.
        <br />
        <br />
        ‘Tax Canvas’는 『약관의 규제에 관한 법률』, 『전자상거래 등에서의 소비자보호에
        관한 법률(이하 “전자상거래법”)』, 『정보통신망 이용촉진 및 정보보호(이하
        "정보통신망법")』, 『소비자기본법』, 『전자문서 및 전자거래 기본법』 등 관련법에
        위배되지 않는 범위 내에서 본 약관을 개정할 수 있습니다.
        <br />
        <br />
        ‘Tax Canvas’가 본 약관을 개정할 경우 개정내용과 적용일자를 명시하여 적용일자 7일
        전부터 적용일자 전일까지 통상적이고 합리적인 방법으로 ‘사용자’에게 통지합니다.
        다만, ‘사용자’에게 불리하게 개정되는 경우에는 적용일자 30일 전부터 적용일자까지
        공지하고, ‘사용자’가 기재한 전자우편주소 또는 홈페이지 로그인 시 노출되는 팝업 창
        등의 전자적 수단을 통해 별도로 통지합니다.
        <br />
        <br />
        ‘Tax Canvas’가 전항에 따라 개정약관을 공지하면서 일정한 기간 내에 의사표시를 하지
        않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게 공지 또는 통지하였음에도
        ‘사용자’가 명시적으로 거부의 의사표시를 하지 아니한 경우 개정약관에 동의한 것으로
        봅니다.
        <br />
        <br />
        ‘사용자’는 개정약관에 동의하지 않는 경우에는 개정 약관의 적용일 이전에 명시적인
        거부 의사를 표시하고 이용계약을 해지할 수 있습니다.
        <br />
        <br />
        ‘사용자’는 약관 변경에 대하여 주의의무를 다하여야 하며, 개정 약관의 부지로 인한
        ‘사용자’의 피해는 ‘Tax Canvas’가 책임지지 않습니다.
        <br />
        <br />
        <br />
        <br />
        제 5 조 (개별 서비스에 대한 약관 및 약관의 해석)
        <br />
        <br />
        ‘Tax Canvas’는 필요한 경우 특정 서비스에 관하여 적용될 사항(이하 "개별약관") 및
        세부적인 내용(이하 "이용정책")을 정하여 운영할 수 있으며, 해당 내용은 홈페이지
        등을 통해 공지합니다.
        <br />
        <br />
        특정 서비스에 대한 개별약관이 본 약관과 상충할 경우에는 개별약관이 우선하여
        적용됩니다.
        <br />
        <br />
        ‘사용자’는 항상 개별약관이나 이용정책의 내용에 변경이 있는지를 주시하여야 하며,
        변경사항의 공지가 있을 시에는 이를 확인하여야 합니다.
        <br />
        <br />
        본 약관 또는 개별약관에서 정하지 않은 사항은 『전기통신사업법』, 『전자문서 및
        전자거래 기본법』, 『정보통신망 이용촉진 및 정보보호 등에 관한 법률』,
        『전자상거래 등에서의 소비자보호에 관한 법률』, 『개인정보보호법』 등 관련 법의
        규정 및 ‘Tax Canvas’가 정한 서비스의 세부이용지침 등의 규정에 따릅니다. 또한 본
        약관에서 정한 ‘Tax Canvas’의 책임 제한 규정은 관련 법령이 허용하는 최대한도의 범위
        내에서 적용됩니다.
        <br />
        <br />
        <br />
        <br />
        제 2 장 서비스 이용계약의 체결과 기간, 해지
        <br />
        <br />
        제 6 조 (이용계약의 체결)
        <br />
        <br />
        이용계약은 ‘사용자’가 되고자 하는 자(이하 "가입신청자")가 약관의 내용에 대하여
        동의를 한 다음 ‘서비스’ 이용 신청을 하고, ‘Tax Canvas’가 이러한 신청에 대하여
        승낙함으로써 체결됩니다. 다만, 가입신청자가 가입신청 당시 이 약관의 내용과 함께
        제공한 동의 확인란에 동의를 표시한 경우, ‘Tax Canvas’는 가입신청자가 이 약관의
        내용을 모두 읽고 이를 충분히 이해하였으며, 이를 적용하는 것에 동의한 것으로
        간주합니다.
        <br />
        <br />
        ‘Tax Canvas’는 가입신청자의 신청에 대하여 서비스 이용을 승낙함을 원칙으로 합니다.
        다만, ‘Tax Canvas’는 다음 각 호에 해당하는 신청에 대하여는 승낙을 하지 않거나
        사후에 이용계약을 해지할 수 있습니다.
        <br />
        <br />
        ① 이미 가입된 회원과 정보가 동일한 경우
        <br />
        <br />
        ② 실명이 아니거나 타인의 명의를 도용 혹은 등록내용에 허위, 기재누락이 있는 경우
        <br />
        <br />
        ③ 허위의 정보를 기재하거나, 필수적으로 표기해야 할 사항을 기재하지 않은 경우
        <br />
        <br />
        ④ 부정한 용도 및 불법적인 목적으로 서비스를 사용하고자 하는 경우
        <br />
        <br />
        ⑤ 가입신청자의 귀책사유로 인하여 승인이 불가능하거나 ‘Tax Canvas’가 정한 제반
        요구사항을 위반하여 신청하는 경우
        <br />
        <br />
        ⑥ 기타 ‘Tax Canvas’가 합리적인 판단에 의하여 필요하다고 인정하는 경우
        <br />
        <br />
        제1항에 따른 신청에 있어 ‘Tax Canvas’는 전문기관을 통한 실명확인 및 본인인증을
        요청할 수 있습니다.
        <br />
        <br />
        제1항에 따른 신청에 있어 ‘Tax Canvas’는 제공하는 서비스의 종류에 따라 필요한 경우
        가입신청자에게 추가 정보를 요청할 수 있습니다.
        <br />
        <br />
        이용계약의 성립 시기는 ‘Tax Canvas’가 가입완료를 신청절차 상에서 표시하거나 정하는
        방법으로 ‘Tax Canvas’가 가입신청자에게 가입완료 안내를 발송하여 도달한 시점으로
        합니다.
        <br />
        <br />
        <br />
        <br />
        제 7 조 (이용계약의 해지 및 이용제한)
        <br />
        <br />
        ‘사용자’는 언제든지 ‘Tax Canvas’가 정한 절차에 따라 가입 탈퇴를 요청하여 이용계약
        해지 신청을 할 수 있으며, ‘Tax Canvas’는 관련법 등이 정하는 바에 따라 이를 즉시
        처리합니다.
        <br />
        <br />
        이용계약의 종료와 관련하여 발생한 손해는 이용계약이 종료된 해당 ‘사용자’가 책임을
        부담하며, ‘Tax Canvas’는 이에 대해 일체의 책임을 부담하지 않습니다.
        <br />
        <br />
        ‘사용자’가 계약을 해지하는 경우에도 ‘사용자’가 작성하였던 게시물 등은 삭제되지
        않으며, 탈퇴로 인하여 ‘사용자’ 정보가 삭제되어 작성자 본인을 확인할 수 없어 게시물
        편집 및 삭제가 원천적으로 불가할 수 있습니다.
        <br />
        <br />
        <br />
        <br />
        제 3 장 서비스의 이용
        <br />
        <br />
        제 8 조 (서비스의 내용 및 제공)
        <br />
        <br />
        ‘Tax Canvas’가 본 약관에 따라 ‘사용자’에게 제공하는 서비스는 아래 각 호와
        같습니다. 단, ‘Tax Canvas’는 일부 서비스의 이용조건 및 이용범위 등을 별도로 정하여
        운영할 수 있으며 해당 사항은 ‘사용자’에게 공지합니다. ① ‘사용자’의 업무 생산성
        제고를 위해 기타 ‘Tax Canvas’가 추가 개발하거나 다른 회사와의 제휴 계약 등을 통해
        ‘사용자’에게 제공 하는 일체의 서비스
        <br />
        <br />
        ‘사용자’의 특정 서비스에 대한 접근은 비밀번호로 보호됩니다. ‘사용자’는 비밀번호를
        기밀로 유지해야 할 책임이 있으며, 암호를 공유하는 것은 엄격히 금지됩니다.
        <br />
        <br />
        ‘Tax Canvas’는 서비스를 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.
        <br />
        <br />
        ‘Tax Canvas’는 서비스의 제공에 필요한 경우 정기점검 혹은 수시점검을 실시할 수
        있으며, 점검시간은 공지한 바에 따릅니다. 정기점검시간은 사전에 공지하는 것을
        원칙으로 하나 부득이 한 사유가 있는 경우 사후에 통지할 수 있습니다.
        <br />
        <br />
        ‘사용자’는 별도 안내되는 ‘서비스 이용요금 및 취소∙환불 정책’을 충분히 숙지하고
        서비스를 이용하여야 합니다.
        <br />
        <br />
        <br />
        <br />
        제 9 조 (서비스의 변경, 중지 및 종료)
        <br />
        <br />
        ‘Tax Canvas’는 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라 제공하고
        있는 전부 또는 일부 서비스를 변경할 수 있습니다.
        <br />
        <br />
        ‘Tax Canvas’는 서비스의 변경 또는 서비스 중단이 예정된 경우 예정일로부터 30일
        이상의 기간을 두고 회원이 충분히 인지할 수 있는 방법으로 홈페이지(서비스
        초기화면)에 변경 또는 중단될 서비스의 종류, 변경 또는 중단 내용과 사유, 변경 또는
        중단 일시 등을 공지합니다.
        <br />
        <br />
        ‘Tax Canvas’는 다음 각 호에 해당하는 경우 서비스의 일부 또는 전부의 제공을
        일시적으로 중단할 수 있습니다. 이 경우 ‘Tax Canvas’는 서비스 이용의 중단 사실을
        제4조의 방법에 따라 즉시 공지합니다. 다만, ‘Tax Canvas’가 사전에 통지할 수 없는
        부득이 한 사유가 있는 경우 사후에 통지할 수 있습니다.
        <br />
        <br />
        ① 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 서버의 증설 및 교체, 운영자의
        고의∙과실이 없는 장애 등의 부득이한 경우
        <br />
        <br />
        ② 「전기통신사업법」에 규정된 기간통신사업자가 전기통신서비스를 중지하는 경우
        <br />
        <br />
        ③ 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인
        서비스 제공에 지장이 있는 경우
        <br />
        <br />
        ④ 특정 ‘사용자’가 ‘Tax Canvas’가 제공하는 통상적인 수준의 트래픽, 용량 등을
        과도하게 초과하여 ‘Tax Canvas’의 정상적인 서비스 제공에 지장을 초래할 우려가 있는
        경우
        <br />
        <br />
        ‘Tax Canvas’는 이용 감소로 인한 원활한 서비스 제공의 곤란 및 수익성 악화, 기술
        진보에 따른 차세대 서비스로의 전환 필요성, 서비스 제공과 관련한 ‘Tax Canvas’
        정책의 변경 등 기타 상당한 이유가 있는 경우에 운영상, 기술상의 필요에 따라
        제공하고 있는 전부 또는 일부 서비스를 변경 또는 중단할 수 있습니다.
        <br />
        <br />
        ‘Tax Canvas’가 서비스 제공을 종료하고자 할 경우, 서비스를 종료하고자 하는 날로부터
        1개월 이전에 본 약관 제4조에 규정된 통지방법을 준용하여 ‘사용자’에게 알려드립니다.
        <br />
        <br />
        ‘Tax Canvas’는 본 조항에 따른 서비스의 변경, 중지 및 종료로 발생하는 문제에 대해서
        어떠한 책임도 지지 않습니다.
        <br />
        <br />
        <br />
        <br />
        제 10 조 (서비스의 이용)
        <br />
        <br />
        서비스는 아래의 특별약관에 따라 운영됩니다.
        <br />
        <br />
        ① ‘사용자’가 에 따라 정보 입력 및 대금 결제
        <br />
        <br />
        ② 대금 결제와 동시에 검색 및 프로그램 사용권한 부여
        <br />
        <br />
        서비스 이용과 관련하여 ‘사용자’가 입력한 정보에 오류가 있음으로 인해 발생하는
        법률상 책임과 불이익은 전적으로 ‘사용자’가 부담하여야 합니다.
        <br />
        <br />
        <br />
        <br />
        제 11 조 (정보의 제공)
        <br />
        <br />
        ‘Tax Canvas’는 ‘사용자’가 이 서비스를 이용하는 중 필요하다고 인정되는 다양한
        정보를 공지사항이나 전자우편, SMS, 푸쉬 메시지 등의 방법으로 ‘사용자’에게 제공할
        수 있습니다. 다만, ‘사용자’는 관련법에 따른 거래관련 정보 및 고객문의 등에 대한
        답변을 제외하고는 언제든지 마케팅 수신 동의를 철회하실 수 있습니다.
        <br />
        <br />
        ‘Tax Canvas’는 서비스의 운영과 관련하여 서비스 화면, 홈페이지, 전자우편 등에
        광고를 게재할 수 있습니다. 광고가 게재된 전자우편을 수신한 ‘사용자’는 수신거절을
        ‘Tax Canvas’에게 할 수 있습니다.
        <br />
        <br />
        ‘Tax Canvas’는 ‘사용자’의 게시물 내용, 검색 내용뿐만 아니라 언어, 쿠키 및
        기기정보, IP 주소, 브라우저 유형, 운영체제 및 요청 일시와 같은 표준 로그 정보 등을
        활용한 맞춤광고를 제공할 수 있습니다. 이에 대한 자세한 사항은 을 참고하시기
        바랍니다.
        <br />
        <br />
        <br />
        <br />
        제 12 조 (게시물의 저작권 및 이용허락)
        <br />
        <br />
        ‘사용자’가 작성한 게시물의 저작권은 이를 작성한 ‘사용자’ 본인에게 있으며, 해당
        게시물이 타인의 저작권을 침해하거나 개인정보를 유출하는 경우 그에 대한 책임도
        ‘사용자’ 본인이 부담합니다.
        <br />
        <br />
        <br />
        <br />
        제 13 조 (IP권리의 귀속)
        <br />
        <br />
        ‘Tax Canvas’가 작성한 저작물에 대한 저작권 및 ‘Tax Canvas’가 라이선스를 얻어
        제공하는 기타 저작물의 IP권리는 모두 ‘Tax Canvas’에 귀속됩니다.
        <br />
        <br />
        ‘사용자’는 서비스에 게재 또는 검색된 정보 중 ‘Tax Canvas’의 IP권리에 귀속된 정보를
        ‘Tax Canvas’의 사전 승낙없이 복제, 송신, 출판, 배포, 방송 및 기타 방법에 의하여
        영리 또는 비영리 목적으로 이용하거나 제 3자에게 이용하게 하여서는 안됩니다.
        <br />
        <br />
        ‘사용자’는 본 이용약관으로 인하여 서비스를 소유하거나 서비스에 관한 저작권을
        보유하게 되는 것이 아니며, ‘사용자’는 정보취득의 용도로 ‘Tax Canvas’로부터
        서비스의 이용을 허락받게 되는 것입니다.
        <br />
        <br />
        <br />
        <br />
        제 4 장 권리와 의무
        <br />
        <br />
        제 14 조 (‘Tax Canvas’의 의무)
        <br />
        <br />
        ‘Tax Canvas’는 관련 법령과 이 약관이 금지하거나 공공질서, 미풍양속에 반하는 행위를
        하지 않으며, 이 약관이 정하는 바에 따라 지속적이고 안정적으로 서비스를 제공하기
        위하여 최선을 다합니다.
        <br />
        <br />
        ‘Tax Canvas’는 ‘사용자’가 안전하게 서비스를 이용할 수 있도록 ‘사용자’의
        개인정보(신용정보 포함) 보호를 위한 보안시스템을 갖추어야 하며 , ‘사용자’의
        개인정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않고, 이를 보호하기 위하여
        노력합니다.
        <br />
        <br />
        ‘Tax Canvas’는 전자금융거래가 안전하게 처리될 수 있도록 선량한 관리자로서의 주의를
        다하며, 전자금융거래의 안전성과 신뢰성을 확보할 수 있도록 전자금융거래의 종류별로
        전자적 전송이나 처리를 위한 인력, 시설, 전자적 장치 등의 정보기술 부분 및
        전자금융업무에 관하여 금융감독위원회가 정하는 기준을 준수합니다.
        <br />
        <br />
        ‘Tax Canvas’는 서비스 이용과 관련하여 발생하는 ‘사용자’의 불만 및 피해구제 요청을
        적절하게 처리할 수 있도록 필요한 인력 및 시스템을 구비하며, ‘Tax Canvas’는 서비스
        이용과 관련하여 ‘사용자’으로부터 제기된 의견이나 불만이 정당하다고 인정할 경우에는
        이를 처리하여야 합니다. ‘사용자’이 제기한 의견이나 불만사항에 대해서는 전자우편
        등을 통하여 ‘사용자’에게 처리과정 및 결과를 전달합니다.
        <br />
        <br />
        ‘Tax Canvas’는 ‘사용자’로부터의 거래지시가 있음에도 불구하고 천재지변, ‘Tax
        Canvas’의 귀책사유가 없는 정전, 화재, 통신장애 기타의 불가항력의 사유로 처리
        불가능하거나 지연된 경우에는 ‘사용자’에 대하여 이로 인한 책임을 지지 아니합니다.
        <br />
        <br />
        ‘Tax Canvas’가 제공하는 서비스로 인하여 ‘사용자’에게 손해가 발생한 경우 그러한
        손해가 ‘Tax Canvas’의 고의나 과실에 의해 발생한 경우에 한하여 책임을 부담하며, 그
        책임의 범위는 통상손해에 한합니다.
        <br />
        <br />
        <br />
        <br />
        제 15 조 (‘사용자’의 의무)
        <br />
        <br />
        ‘사용자’는 기타 관계 법령, 본 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항,
        ‘Tax Canvas’가 통지하는 사항 등을 준수하여야 하며, 기타 ‘Tax Canvas’의 업무에
        방해되는 행위를 하여서는 아니 됩니다.
        <br />
        <br />
        ‘사용자’는 서비스의 이용권한, 기타 서비스 이용계약상의 지위를 타인에게 양도,
        증여할 수 없으며 이를 담보로 제공할 수 없습니다.
        <br />
        <br />
        ‘사용자’는 서비스 이용과 관련하여 다음 각 호의 행위를 하여서는 안됩니다.
        <br />
        <br />
        ① 서비스 신청 또는 변경 시 허위내용을 등록하는 행위
        <br />
        <br />
        ② 다른 회원의 아이디 및 비밀번호를 도용하여 부당하게 서비스를 이용하거나, 정보를
        도용 하는 행위
        <br />
        <br />
        ③ 타인의 계좌번호 및 신용카드번호 등 타인의 허락 없이 타인의 결제정보를 이용하여
        서비스 를 이용하는 행위
        <br />
        <br />
        ④ 정당한 사유 없이 ‘Tax Canvas’의 영업을 방해하는 행위
        <br />
        <br />
        ⑤ ‘Tax Canvas’가 게시한 정보 기타 데이터를 임의로 변경하는 행위
        <br />
        <br />
        ⑥ ‘Tax Canvas’와 기타 제3자의 저작권 등 IP권리를 침해하는 행위
        <br />
        <br />
        ⑦ ‘Tax Canvas’ 및 기타 제3자의 명예를 손상시키거나 업무를 방해 혹은 손해를 끼치는
        행위
        <br />
        <br />
        ⑧ ‘Tax Canvas’의 동의 없이 영리를 목적으로 서비스를 복제, 송신, 출판, 배포, 방송
        및 기타의 방법으로 사용하는 행위
        <br />
        <br />
        ⑨ 서비스와 관련된 설비의 오동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터
        바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램 자료를 등록 또는 유포하는 행위
        <br />
        <br />
        ⑩ ‘Tax Canvas’가 제공하는 소프트웨어 등을 개작, 리버스 엔지니어링, 디컴파일,
        디스어셈블 및 기타 일체의 가공행위를 통하여 서비스를 복제, 분해 또는 모방 기타
        변형하는 행위
        <br />
        <br />
        ⑪ 자동 접속 프로그램 등을 사용하는 등 정상적인 용법과 다른 방법으로 서비스를
        이용하여 ‘Tax Canvas’의 서버에 부하를 일으켜 ‘Tax Canvas’의 정상적인 서비스를
        방해하는 행위
        <br />
        <br />
        ⑫ 다른 ‘사용자’의 개인정보를 그 동의 없이 수집, 저장, 공개하는 행위
        <br />
        <br />
        ⑬ 기타 불법적이거나 ‘Tax Canvas’에서 정한 규정을 위반하는 행위
        <br />
        <br />
        ‘Tax Canvas’는 ‘사용자’가 위 제2항의 행위를 하는 경우 서비스의 이용을 제한하거나
        일방적으로 본 계약을 해지할 수 있습니다.
        <br />
        <br />
        ‘사용자’는 회원정보, 계정정보에 변경이 있는 경우 즉시 변경하여야 하며, 더불어
        비밀번호를 철저히 관리하여야 합니다. ‘사용자’의 귀책으로 말미암은 관리소홀,
        부정사용 등에 의하여 발생하는 모든 결과에 대한 책임은 ‘사용자’ 본인이 부담하며,
        ‘Tax Canvas’는 이에 대한 어떠한 책임도 부담하지 않습니다.
        <br />
        <br />
        ‘사용자’는 ‘Tax Canvas’에서 명시적으로 승인한 경우를 제외하고는 서비스를 이용하여
        본건 서비스와 동일하거나 유사한 소프트웨어를 제공하는 활동을 할 수 없습니다. 이를
        위반하여 발생한 영업 활동의 결과 및 손실, 관할 규제기관의 제재 등 법적 조치 등에
        관해서는 ‘사용자’가 온전히 책임을 부담하며, ‘사용자’는 이와 같은 행위와 관련하여
        ‘Tax Canvas’에 발생한 손해를 배상하여야 할 의무를 집니다.
        <br />
        <br />
        <br />
        <br />
        제 5 장 정보의 보호
        <br />
        <br />
        제 16 조 (개인정보보호)
        <br />
        <br />
        ‘Tax Canvas’는 ‘사용자’가 안전하게 서비스를 이용할 수 있도록 ‘사용자’의
        개인정보보호를 위하여 ‘개인정보 보호정책’을 실시하며, 이에 따라 ‘사용자’의
        개인정보보호를 최대한 보호하기 위하여 노력합니다.
        <br />
        <br />
        ‘Tax Canvas’의 ‘개인정보 보호정책’은 을 통해 확인할 수 있습니다. 단, ‘Tax
        Canvas’의 공식 홈페이지 이외의 링크된 사이트에서는 회사의 개인정보 처리방침이
        적용되지 않습니다. 링크된 사이트 및 서비스를 제공하는 제3자의 개인정보 처리에
        대해서는 해당 사이트 및 제3자의 개인정보 처리방침을 확인할 책임이 ‘사용자’에게
        있으며, ‘Tax Canvas’는 이에 대하여 책임을 부담하지 않습니다.
        <br />
        <br />
        <br />
        <br />
        제 6 장 기타
        <br />
        <br />
        제 17 조 (손해배상)
        <br />
        <br />
        1. ‘사용자’가 본 약관의 규정을 위반함으로 인하여 ‘Tax Canvas’에 손해가 발생하게
        되는 경우, 본 약관을 위반한 ‘사용자’는 ‘Tax Canvas’에 발생하는 모든 손해를
        배상하여야 합니다.
        <br />
        <br />
        2. ‘사용자’가 서비스를 이용하는 과정에서 행한 불법행위나 본 약관 위반행위로 인하여
        ‘Tax Canvas’가 당해 ‘사용자’ 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한
        각종 이의제기를 받는 경우 당해 ‘사용자’는 자신의 책임과 비용으로 ‘Tax Canvas’를
        면책시켜야 하며, ‘Tax Canvas’가 면책되지 못한 경우 당해 ‘사용자’가 그로 인하여
        ‘Tax Canvas’에 발생한 모든 손해를 배상하여야 합니다.
        <br />
        <br />
        <br />
        <br />
        제 18 조(‘Tax Canvas’의 책임제한)
        <br />
        <br />
        ‘Tax Canvas’는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수
        없는 경우에는 서비스 제공에 관한 책임이 면책됩니다.
        <br />
        <br />
        ‘Tax Canvas’는 ‘사용자’의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지
        않습니다.
        <br />
        <br />
        ‘Tax Canvas’는 ‘사용자’가 본 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여
        책임을 지지 않습니다. 또한 ‘Tax Canvas’는 ‘사용자’가 게재한 게시물의 정확성 등
        내용에 대하여는 책임을 지지 않습니다.
        <br />
        <br />
        ‘Tax Canvas’는 ‘Tax Canvas’의 임직원 및 ‘Tax Canvas’ 대리인의 고의 또는 중대한
        과실이 없는 한 다음 각 호의 사항으로부터 발생하는 손해에 대해 책임을 지지
        않습니다.
        <br />
        <br />
        ① ‘사용자’ 정보 등의 허위 또는 부정확성에 기인하는 손해
        <br />
        <br />
        ② 서비스에 대한 접속 및 서비스의 이용과정에서 발생하는 개인적인 손해
        <br />
        <br />
        ③ 서버에 대한 제3자의 모든 불법적인 접속 또는 서버의 불법적인 이용으로부터
        발생하는 손해
        <br />
        <br />
        ④ 서버에 대한 전송 또는 서버로부터의 전송에 대한 제3자의 모든 불법적인 방해 또는
        중단 행위로부터 발생하는 손해
        <br />
        <br />
        ⑤ 제3자가 서비스를 이용하여 불법적으로 전송, 유포하거나 또는 전송, 유포되도록 한
        모든 바이러스, 스파이웨어 및 기타 악성 프로그램으로 인한 손해
        <br />
        <br />
        ⑥ 전송된 데이터의 오류 및 생략, 누락, 파괴 등으로 발생되는 손해
        <br />
        <br />
        ‘Tax Canvas’는 무료로 제공되는 서비스 이용과 관련하여 관련법에 특별한 규정이 없는
        한 책임을 지지 않습니다.
        <br />
        <br />
        <br />
        <br />
        제 19 조 (분쟁조정)
        <br />
        <br />
        1. ‘Tax Canvas’는 ‘사용자’으로부터 소정의 절차에 의해 제기되는 의견이나 불만이
        정당하다고 인정할 경우에는 적절한 절차를 거쳐 처리합니다. 단, 처리 시 일정 기간이
        소요될 경우 ‘사용자’에게 그 사유와 처리 일정을 즉시 통보합니다.
        <br />
        <br />
        2. ‘사용자’는 ‘Tax Canvas’의 분쟁처리결과에 대하여 이의가 있을 경우 '금융위원회의
        설치 등에 관한 법률'에 따른 금융감독원의 금융분쟁조정위원회나 '소비자기본법'에
        따른 한국소비자원의 소비자분쟁조정위원회, ‘전자문서 및 전자거래기본법’ 제32조 및
        동 시행령 제20조에 의하여 설치된 전자거래분쟁조정위원회에 ‘Tax Canvas’의
        전자금융거래서비스의 이용과 관련한 분쟁조정을 신청할 수 있습니다.
        <br />
        <br />
        <br />
        <br />
        제 20 조 (준거법 및 재판관할)
        <br />
        <br />
        이 약관과 관련된 사항에 대하여는 대한민국 법을 준거법으로 합니다.
        <br />
        <br />
        ‘Tax Canvas’와 ‘사용자’간 발생한 분쟁은 민사소송법 상의 관할법원에 관할이
        있습니다.
        <br />
        <br />
        <br />
        <br />
        제 21 조 (인수 및 합병)
        <br />
        <br />
        ‘Tax Canvas’는 인수 또는 합병이 되는 경우, 보관하고 있는 ‘사용자’의 개인정보 또한
        인수자에게 이관하며, 개인정보 이전에 관해서는 ‘사용자’의 동의가 있는 한에서만
        절차를 진행합니다. 이때 동의 방법에 관하여는 제4조의 방법을 준용합니다.
        <br />
        <br />
        인수자는 서비스 ‘사용자’에게 기존 ‘사용자’ 지위의 유지 또는 탈퇴의 기회를
        제공합니다.
        <br />
        <br />
        <br />
        <br />
        부칙
        <br />
        <br />
        본 약관은 2024년 7월 1일부터 시행합니다.
        <br />
        <br />
        본 약관에 대한 저작권은 Tax Canvas에 있으며 무단 복제, 배포, 전송, 기타 저작권
        침해행위를 엄금합니다.
        <br />
        <br />
      </div>
      <CustomTButton text={"확인"} onClick={closeModal} />
    </StyledTermsOfUseModalComponent>
  );
};

const StyledTermsOfUseModalComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-weight: 500;
    margin: 27px;
  }

  .content {
    max-height: 570px;
    overflow-y: scroll;
    width: 760px;
    padding: 25px 30px;
    background-color: ${colors.bg_con};
    border-radius: 12px;

    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
  }
`;

const CustomTButton = styled(TButton)`
  margin-top: 15px;
  font-size: 16px;
  width: 380px;
`;

export default TermsOfUseModalComponent;
