import React from "react";
import styled from "styled-components";
import { TButtonComponentProps } from "@/types/components/tbuttonProps";
import { colors } from "@/styles/color";
import { title16 } from "@/styles/typography";

const TButton: React.FC<TButtonComponentProps> = (props: TButtonComponentProps) => {
  return (
    <StyledButtonComponent onClick={props.onClick} {...props}>
      {props.prepend}
      {props.text}
      {props.append}
    </StyledButtonComponent>
  );
};

const StyledButtonComponent = styled.button<TButtonComponentProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  margin: 0;

  border: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : `0px`)};

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  width: 100%;
  border-radius: ${(props) => (props.borderRadius ? `${props.borderRadius}px` : "12px")};
  padding: ${(props) => (props.padding ? props.padding : "15px 0 14px 0")};

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  pointer-events: ${(props) => (props.disabled ? "none" : "auto")};

  ${(props) => props.fontSize || title16}

  color: ${(props) => props.$textcolor || colors.white};

  ${(props) =>
    props.type === "gradient"
      ? "background: linear-gradient(90deg, rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0));"
      : props.type === "shadow"
      ? "box-shadow: 0px 10px 20px 0px rgba(17, 17, 136, 0.1);"
      : ""}
  background-color: ${(props) =>
    props.type === "link" ? "transparent;" : props.$bgcolor || colors.primary_main};

  opacity: ${(props) => (props.disabled ? 0.4 : 1.0)};

  &:hover {
    opacity: 0.8;
    ${(props) => (props.type === "link" ? "text-decoration: underline;" : "")}
  }
`;

export default TButton;
