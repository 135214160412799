import React from "react";
import styled, { css } from "styled-components";
import { TextButtonComponentProps } from "../../../types/components/buttonProps";
import { colors } from "@/styles/color";
import { body14 } from "@/styles/typography";

const TextButtonComponent: React.FC<TextButtonComponentProps> = (
  props: TextButtonComponentProps,
) => {
  const action =
    props.action ??
    (() => {
      alert("무료 배포판에서 제공하지 않는 기능입니다.");
    });

  return (
    <StyledTextButtonComponent onClick={() => action()} {...props}>
      {props.text}
    </StyledTextButtonComponent>
  );
};

const StyledTextButtonComponent = styled.button<TextButtonComponentProps>`
  border: none;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  ${(props) =>
    props.fontSize
      ? css`
          ${props.fontSize}
          line-height: 1.5;
        `
      : css`
          ${body14}
          line-height: 1.5;
        `}

  color: ${(props) => props.color || colors.primary_main};
  background-color: transparent;

  text-decration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export default TextButtonComponent;
