import { colors } from "@/styles/color";
import styled from "styled-components";
import TButton from "../guides/button/TButton";

interface PrivacyPolicyModalComponentProps {
  closeModal: () => void;
}

const PrivacyPolicyModalComponent: React.FC<PrivacyPolicyModalComponentProps> = ({
  closeModal,
}: PrivacyPolicyModalComponentProps) => {
  return (
    <StyledPrivacyPolicyModalComponent>
      <div className="title">개인정보 수집 및 이용 동의</div>
      <div className="content">
        택스캔버스는 Tax Canvas서비스 제공과 관련하여 아래와 같이 개인정보를 수집∙이용
        하고자합니다.
        <br />
        <br />
        1. 수집 항목 및 이용 목적
        <br />
        <br />
        가. 일반
        <br />
        <br />
        수집방법: 소셜로그인 및 전화번호 인증
        <br />
        수집항목: ID, 이름, 이메일, 전화번호
        <br />
        수집 및 이용목적: 서비스 이용을 위한 사용자 식별 및 가입의사 확인, 신규 서비스
        안내, 설문, 문의처리, 피드백 접수 및 회신, 회원설정 기반 정보제공
        <br />
        <br />
        ※ Tax Canvas는 고객의 편의를 위해 간편 로그인(네이버, 카카오, 페이스북, 구글,
        Apple) 방식을 제공하고 있습니다. 이와 같은 로그인 시 당사가 고객의 개인정보를 추가
        수집하지 않으며, 본인확인값만 비교하고 있습니다.
        <br />
        <br />
        나. 서비스 이용에 따른 자동 수집 및 생성
        <br />
        <br />
        수집항목: 쿠키, 서비스이용기록(방문일시, IP, 불량이용기록), 검색내역, 시간
        <br />
        이용목적: 접속관리, 활동정보 파악
        <br />
        <br />
        귀하께서는 Tax Canvas가 위와 같이 수집하는 개인정보에 대해 동의하지 않거나
        개인정보를 기재하지 않음으로써 거부할 수 있습니다. 다만, 이때 회원에게 제공되는
        서비스가 제한될 수 있습니다.
        <br />
        <br />
        2. 개인정보 보유 및 이용기간
        <br />
        <br />
        서비스 제공을 위해 수집한 정보: 정보주체가 Tax Canvas 서비스를 위한 회원의 탈퇴
        내지 계약해지의 의사를 표시한 날로부터 30일까지
        <br />
        <br />
        ※ 광고성 정보 수신동의 및 하위 서비스 가입: 동의 철회 시까지
        <br />
        <br />
        Tax Canvas는 이용자의 개인정보를 고지 및 동의 받은 사항에 따라 수집/이용 목적이
        달성되기 전 또는 이용자의 탈퇴 요청이 있기 전까지 해당 정보를 보유합니다. 다만,
        아래의 사유로 인하여 보관이 필요한 경우 외부와 차단된 별도 DB 또는 테이블에 분리
        보관 됩니다.
      </div>
      <CustomTButton text={"확인"} onClick={closeModal} />
    </StyledPrivacyPolicyModalComponent>
  );
};

const StyledPrivacyPolicyModalComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-weight: 500;
    margin: 27px;
  }

  .content {
    max-height: 570px;
    overflow-y: scroll;

    width: 760px;
    padding: 25px 30px;
    background-color: ${colors.bg_con};
    border-radius: 12px;

    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    .number-title {
      margin-left: 5px;
    }
  }
`;

const CustomTButton = styled(TButton)`
  margin-top: 15px;
  font-size: 16px;
  width: 380px;
`;
export default PrivacyPolicyModalComponent;
