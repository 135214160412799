export const DOCUMENT = [
  "전체",
  "판례",
  "과세적부",
  "이의신청",
  "심판청구",
  "심사청구",
  "헌재",
  "사전답변",
  "질의회신",
  "과세기준자문",
  "고시서면질의",
] as const;

export const JUDGEMENT = [
  "전체",
  "각하",
  "국승",
  "국패",
  "일부국패",
  "유죄",
  "일부유죄",
  "무죄",
  "기타",
] as const;

export const TAXATION = ["전체", "각하", "채택", "불채택", "일부채택", "기각"] as const;

export const APPEAL = ["전체", "각하", "기각", "인용", "일부인용", "기타"] as const;

export const INSPECTION_REQUEST = [
  "전체",
  "각하",
  "기각",
  "인용",
  "일부인용",
  "기타",
] as const;

export const TRIAL_REQUEST = [
  "전체",
  "각하",
  "기각",
  "인용",
  "일부인용",
  "기타",
] as const;

export const COURT = ["전체", "각하", "기각", "인용", "일부인용", "기타"] as const;
