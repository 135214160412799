import React, { useEffect } from "react";
import FadeLoader from "react-spinners/FadeLoader";
import { colors } from "@/styles/color";
import styled from "styled-components";
import ModalPortal from "@/components/modal/ModalPortal";
import { allowScroll, preventScroll } from "@/utils/modal";

const SpinnerComponent: React.FC = () => {
  useEffect(() => {
    const prevScrollY = preventScroll();
    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  return (
    <ModalPortal>
      <StyledSpinner>
        <FadeLoader
          color={colors.primary_main}
          height={15}
          width={5}
          radius={2}
          margin={2}
        />
      </StyledSpinner>
    </ModalPortal>
  );
};

export default SpinnerComponent;

const StyledSpinner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;
