import instance from "./axios";

export const getRelatedCases = async ({
  projectId,
  contentId,
  page,
}: {
  projectId: string;
  contentId: number;
  page: number;
}) => {
  return await instance.get(
    `/content/${contentId}/cases?projectId=${projectId}&page=${page}`,
  );
};
