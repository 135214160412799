/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

class ApiError<T = unknown> extends Error implements AxiosError<T> {
  conifg: AxiosRequestConfig;
  code?: string;
  message: string;
  request?: any;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => any;

  constructor(error: AxiosError<T>, message?: string) {
    super();

    const errorStatus = error.response?.status || 0;

    this.message = message ?? error.message;
    this.name = getErrorName(errorStatus);
    this.stack = error.stack;
    this.conifg = error.config;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.isAxiosError = error.isAxiosError;
    this.toJSON = error.toJSON;
  }
}

const getErrorName = (status) => {
  let name = "Api Error";
  switch (status) {
    case 400:
      name = "Bad Request";
      break;
    case 401:
      name = "Unauthorized";
      break;
    case 403:
      name = "Forbidden";
      break;
    case 404:
      name = "Not Found";
      break;
    case 405:
      name = "Method Not Allowed";
      break;
    case 429:
      name = "Too Many Requests";
      break;
    case 500:
      name = "Internal Server Error";
      break;
    case 503:
      name = "Time Out";
      break;
    default:
      name = `[${status}] Api Error`;
  }

  return name;
};

export default ApiError;
