import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import naverSvg from "@/assets/icons/36px/naver.svg";
import IconButtonComponent from "../guides/button/IconButton";

const NaverAuthenticationComponent: React.FC = () => {
  const CLIENT_ID = import.meta.env.VITE_NAVER_CLIENT_KEY;
  const NAVER_REDIRECT_URI = import.meta.env.VITE_NAVER_REDIRECT_URI;

  const naverLogin: MouseEventHandler<HTMLDivElement> = () => {
    window.location.href = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${CLIENT_ID}&state=flase&redirect_uri=${NAVER_REDIRECT_URI}`;
  };

  return (
    <StyledNaverAuthenticationComponent>
      <IconButtonComponent
        action={naverLogin}
        text={"네이버로 시작하기"}
        icon={<img src={naverSvg} alt="Naver" />}
      />
    </StyledNaverAuthenticationComponent>
  );
};

const StyledNaverAuthenticationComponent = styled.div``;

export default NaverAuthenticationComponent;
