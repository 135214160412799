import React, { useRef, useEffect, useState, ReactElement } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import editIcon from "@/assets/icons/20px/edit.svg";
// import shareIcon from "@/assets/icons/20px/share.svg";
import deleteIcon from "@/assets/icons/20px/trashIcon.svg";
import { colors } from "@/styles/color";
// import ProjectShareModalContent from "./ProjectShareModalContent";
import ProjectRenameModalContent from "./ProjectRenameModalContent";
import ProjectDeleteModalContent from "./ProjectDeleteModalContent";
import DimmedModalWithCloseComponent from "@/components/guides/modal/DimmedModalWithClose";

// const ShareIcon = () => (
//   <svg
//     width="24"
//     height="24"
//     viewBox="0 0 24 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <image href={shareIcon} width="24" height="24" />
//   </svg>
// );

const RenameIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <image href={editIcon} width="24" height="24" />
  </svg>
);

const DeleteIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <image
      href={deleteIcon}
      width="24"
      height="24"
      style={{
        filter:
          "brightness(0) saturate(100%) invert(46%) sepia(44%) saturate(4538%) hue-rotate(332deg) brightness(102%) contrast(103%)",
      }}
    />
  </svg>
);

const ProjectMenu: React.FC<{
  projectId: string;
  projectName: string;
  projectLength: number;
  isOpen: boolean;
  onClose: () => void;
  onChangeName: () => void;
  anchorRef: React.RefObject<HTMLDivElement>;
}> = ({
  projectId,
  projectName,
  projectLength,
  isOpen,
  onClose,
  onChangeName,
  anchorRef,
}) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<ReactElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !anchorRef.current.contains(event.target as Node)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen && !isModalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, isModalOpen]);

  if (!isOpen || !anchorRef.current) return null;

  const anchorRect = anchorRef.current.getBoundingClientRect();

  const closeProjectMenuModal = () => {
    setIsModalOpen(() => false);
    onClose();
  };

  // const openShareModal = () => {
  //   setModalContent(
  //     <ProjectShareModalContent
  //       projectId={projectId}
  //       projectName={projectName}
  //       handleModal={setIsModalOpen}
  //       onShare={onChangeName}
  //       onClose={onClose}
  //     />,
  //   );
  //   setIsModalOpen(true);
  //   document.removeEventListener("mousedown", handleClickOutside);
  // };

  const openRenameModal = () => {
    setModalContent(
      <ProjectRenameModalContent
        projectId={projectId}
        projectName={projectName}
        handleModal={setIsModalOpen}
        onChangeName={onChangeName}
        onClose={onClose}
      />,
    );
    setIsModalOpen(true);
    document.removeEventListener("mousedown", handleClickOutside);
  };

  const openDeleteModal = () => {
    setModalContent(
      <ProjectDeleteModalContent
        projectId={projectId}
        projectName={projectName}
        projectLength={projectLength}
        handleModal={setIsModalOpen}
        onDelete={onChangeName}
        onClose={onClose}
      />,
    );
    setIsModalOpen(true);
    document.removeEventListener("mousedown", handleClickOutside);
  };

  // const companyPlan = JSON.parse(localStorage.getItem("user")).companyPlan;

  return ReactDOM.createPortal(
    <>
      <PopupMenuContainer
        ref={menuRef}
        style={{ top: anchorRect.top - 48, left: anchorRect.right + 10 }}
      >
        {/* {companyPlan === "Team" && (
          <MenuItem onClick={openShareModal}>
            <ShareIcon />
            <MenuItemText>공유하기</MenuItemText>
          </MenuItem>
        )} */}

        <MenuItem onClick={openRenameModal}>
          <RenameIcon />
          <MenuItemText>이름 변경하기</MenuItemText>
        </MenuItem>

        <MenuItem onClick={openDeleteModal}>
          <DeleteIcon />
          <MenuItemText>프로젝트 삭제</MenuItemText>
        </MenuItem>
      </PopupMenuContainer>

      {isModalOpen && modalContent && (
        <DimmedModalWithCloseComponent
          isOpened={isModalOpen}
          closeModal={closeProjectMenuModal}
          content={modalContent}
        />
      )}
    </>,
    document.body,
  );
};

export default ProjectMenu;

const PopupMenuContainer = styled.div`
  border-radius: 12px;
  border: 1px solid ${colors.neutral_g2};
  width: 200px;
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 999;
  overflow: hidden;
`;

const MenuItem = styled.div`
  flex-grow: 1;
  padding: 8px 16px;
  min-height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.neutral_g2};
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
  &:last-child {
    color: ${colors.secondary_main};
    border-bottom: none;
  }
`;

const MenuItemText = styled.span`
  margin-left: 8px;
`;
