import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import error from "@/assets/error/error.svg";
import { colors } from "@/styles/color";
import { body14, body16, title14, title16, title32, title38 } from "@/styles/typography";

const ErrorPage: React.FC = ({}) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <StyledErrorPage {...{ width: width }}>
      <div className="error-image">
        <img src={error} alt="오류 페이지" />
      </div>
      <div className="error-message">
        <div className="error-title">서비스 오류가 발생했습니다.</div>
        <div className="error-content">
          <div className="error-content-main">
            요청하신 내용이 올바른지 다시 한 번 확인해주세요.
          </div>
          <div className="error-content-sub">
            관련 문의
            <div style={{ color: colors.primary_main, marginLeft: 6 }}>
              taxcanvasdev@gmail.com
            </div>
          </div>
        </div>
      </div>
      <button
        className="error-go-home"
        onClick={() => {
          navigate("/");
        }}
      >
        홈으로 이동
      </button>
    </StyledErrorPage>
  );
};

const StyledErrorPage = styled.div<{ width: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: ${colors.bg_con};

  .error-image {
    img {
      min-width: 60px;
      max-width: 120px;

      @media (max-width: 768px) {
        max-width: 100px;
        min-width: 60px;
      }
    }

    margin-bottom: 40px;
  }

  .error-message {
    .error-title {
      ${css`
        ${title38} line-height: 36px;
      `}
      margin-bottom: 40px;

      @media (max-width: 768px) {
        ${css`
          ${title32} line-height: 30px;
        `}
      }
    }

    .error-content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 60px;

      @media (max-width: 768px) {
        margin-bottom: 45px;
      }

      .error-content-main {
        ${body16}
        margin-bottom: 8px;

        @media (max-width: 768px) {
          ${body14}
        }
      }

      .error-content-sub {
        ${body16}
        display: flex;
        color: ${colors.neutral_g4};

        @media (max-width: 768px) {
          ${body14}
        }
      }
    }
  }

  .error-go-home {
    ${title16}

    display: flex;
    justify-content: center;
    align-items: center;

    width: 190px;
    height: 48px;
    border-radius: 12px;
    border: 1px solid ${colors.neutral_g1};
    background-color: ${colors.white};
    color: ${colors.primary_main};
    box-shadow: 0px 10px 20px 0px rgba(50, 50, 194, 0.1);

    @media (max-width: 768px) {
      ${title14}
      width: 160px;
      height: 42px;
    }

    &:hover {
      border: 1px solid ${colors.neutral_g3};
    }
  }
`;

export default ErrorPage;
