import { create } from "zustand";
import { persist } from "zustand/middleware";

type Textarea = {
  factualRelation?: string;
  keyIssues?: string;
};

type Project = Record<string, Textarea>;

type Store = {
  project?: Project;
  createProject: ({ projectId }) => void;
  getFactualRelation: ({ projectId }) => string;
  getKeyIssues: ({ projectId }) => string;
  setFactualRelation: ({ projectId, factualRelation }) => void;
  setKeyIssues: ({ projectId, keyIssues }) => void;
  deleteProject: ({ projectId }) => void;
};

const useTextareaStore = create<Store>()(
  persist(
    (set, get) => ({
      project: {
        undefined: { keyIssues: "", factualRelation: "" },
      },
      createProject: ({ projectId }) =>
        set(({ project: prevProject }) => ({
          project: {
            ...prevProject,
            [projectId]: { keyIssues: "", factualRelation: "" },
          },
        })),
      getFactualRelation: ({ projectId }) =>
        get().project[projectId]?.factualRelation ?? "",
      getKeyIssues: ({ projectId }) => get().project[projectId]?.keyIssues ?? "",
      setFactualRelation: ({ projectId, factualRelation }) =>
        set(({ project: prevProject }) => ({
          project: {
            ...prevProject,
            [projectId]: { keyIssues: prevProject[projectId].keyIssues, factualRelation },
          },
        })),
      setKeyIssues: ({ projectId, keyIssues }) =>
        set(({ project: prevProject }) => ({
          project: {
            ...prevProject,
            [projectId]: {
              keyIssues: keyIssues,
              factualRelation: prevProject[projectId].factualRelation,
            },
          },
        })),
      deleteProject: ({ projectId }) =>
        set(({ project }) => {
          const prevProject = project;
          delete prevProject[projectId];

          return { project: prevProject };
        }),
    }),
    { name: "textarea-storage" },
  ),
);

// type Store = {
//   factualRelation: string;
//   setFactualRelation: (input: string) => void;

//   keyIssues: string;
//   setKeyIssues: (input: string) => void;
// };

// const useTextareaStore = create<Store>()(
//   persist(
//     (set) => ({
//       factualRelation: "",
//       setFactualRelation: (input) => set(() => ({ factualRelation: input })),

//       keyIssues: "",
//       setKeyIssues: (input) => set(() => ({ keyIssues: input })),
//     }),
//     {
//       name: "textarea-storage",
//     },
//   ),
// );

export default useTextareaStore;
