const Tune = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
    >
      <path
        d="M10.5138 16.9684V12.1339H12.2553V13.7454H19.2213V15.3569H12.2553V16.9684H10.5138ZM3.54785 15.3569V13.7454H8.77234V15.3569H3.54785ZM7.03085 12.1339V10.5224H3.54785V8.91085H7.03085V7.29935H8.77234V12.1339H7.03085ZM10.5138 10.5224V8.91085H19.2213V10.5224H10.5138ZM13.9968 7.29935V2.46484H15.7383V4.07635H19.2213V5.68785H15.7383V7.29935H13.9968ZM3.54785 5.68785V4.07635H12.2553V5.68785H3.54785Z"
        fill="#3232C2"
      />
    </svg>
  );
};

export default Tune;
