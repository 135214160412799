import { colors } from "@/styles/color";
import { body16 } from "@/styles/typography";
import { PropsWithChildren } from "react";
import styled from "styled-components";

interface SearchChipProps {
  type: "main" | "sub";
  onClick?: () => void;
  selected: boolean;
}

const SearchChip = ({ children, ...props }: PropsWithChildren<SearchChipProps>) => {
  return <StyledChip {...props}>{children}</StyledChip>;
};

export default SearchChip;

const StyledChip = styled.div<SearchChipProps>`
  width: fit-content;
  white-space: nowrap;

  ${body16}
  font-weight: 500;

  color: ${({ selected, type }) =>
    selected
      ? type === "main"
        ? colors.primary_main
        : colors.secondary_main
      : colors.black};
  background-color: ${({ selected, type }) =>
    selected
      ? type === "main"
        ? colors.primary_sub
        : colors.secondary_sub
      : colors.white};

  border-radius: 18px;
  padding: 5px 14px;

  cursor: pointer;
`;
