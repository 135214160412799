import React from "react";
import styled from "styled-components";
import { colors } from "@/styles/color";
import { body16, body18, title20 } from "@/styles/typography";
import TButton from "@/components/guides/button/TButton";
import { deleteProject } from "@/utils/coreService";
import { useNavigate } from "react-router-dom";
import useTextareaStore from "@/store/useTextareaStore";

interface ProjectDeleteModalContentProps {
  projectId: string;
  projectName: string;
  projectLength: number;
  onDelete: () => void;
  handleModal: (isOpen: boolean) => void;
  onClose: () => void;
}

const ProjectDeleteModalContent: React.FC<ProjectDeleteModalContentProps> = ({
  projectId,
  projectName,
  projectLength,
  onDelete,
  handleModal,
  onClose,
}) => {
  const userId = JSON.parse(localStorage.getItem("user")).id;

  const navigate = useNavigate();

  const { deleteProject: deleteTextArea } = useTextareaStore();

  const handleDelete = async () => {
    if (projectLength === 1) {
      onClose();
      handleModal(false);

      return alert("프로젝트는 1개 이상 존재해야 합니다");
    }
    await deleteProject({ projectId, userId });

    await onDelete();

    deleteTextArea({ projectId });

    onClose();
    handleModal(false);
    navigate("/project");
  };

  return (
    <StyledProjectDeleteModalContent>
      <div className="modal-header">
        <h2>프로젝트를 삭제하시겠습니까?</h2>
      </div>
      <div className="modal-body">
        <div className="modal-project-name">{projectName}</div>
        <div className="modal-project-inst">프로젝트가 영구적으로 삭제됩니다.</div>
      </div>
      <div className="modal-footer">
        <TButton
          text="삭제"
          fontSize={body16}
          $bgcolor={colors.primary_main}
          $textcolor="white"
          onClick={handleDelete}
        />
      </div>
    </StyledProjectDeleteModalContent>
  );
};

const StyledProjectDeleteModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  background: white;
  width: 530px;
  padding: 25px 15px;

  .modal-header {
    text-align: center;
    h2 {
      ${title20}
      margin: 0;
    }
    .close {
      cursor: pointer;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    height: 175px;
    background-color: ${colors.bg_con};
    margin-top: 20px;
    text-align: center;
    ${body18}
  }

  .modal-project-name {
    color: ${colors.primary_main};
  }

  .modal-footer {
    width: 100%;
    margin-top: 20px;
  }
`;

export default ProjectDeleteModalContent;
