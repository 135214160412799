import instance from "./axios";

export const getProjects = ({ userId }) => {
  return instance.get(`/project/${userId}`);
};

export const postProject = ({ userId }) => {
  return instance.post("/project", { userId });
};

export const patchProjcet = ({ projcetId, userId }) => {
  return instance.patch("/project", { projcetId, userId });
};

export const deleteProject = ({ projectId, userId }) => {
  return instance.delete(`/project/${projectId}`, { data: { userId } });
};

export const patchProjectName = ({ projectId, newProjectName }) => {
  return instance.patch(`/project/${projectId}/name`, { newProjectName });
};

export const getProjectSharedStatus = ({ projectId, userId }) => {
  return instance.get(`/project/${projectId}/share?userId=${userId}`);
};

export const putProjectSharedStatusInfo = ({ projectId, selectedUsers }) => {
  return instance.put(`/project/${projectId}/share`, { selectedUsers });
};

export const getProjectContent = ({
  projectId,
  page,
}: {
  projectId: string;
  page: string;
}) => {
  return instance.get(`/content/${projectId}?page=${page}`);
};

export const postProjectContent = ({ projectId, factualRelation, keyIssues }) => {
  return instance.post(`/content`, {
    projectId,
    factualRelation,
    keyIssues,
  });
};

export const deleteProjectContent = ({
  projectId,
  contentId,
}: {
  projectId: string;
  contentId: number;
}) => {
  return instance.delete(`/content/${contentId}?projectId=${projectId}`);
};

export const getRelatedCaseDetailContents = ({ caseId }) => {
  return instance.get(`/content/case/detail?caseId=${caseId}`);
};

export const getContentRelatedCasesWithFilter = ({
  contentId,
  projectId,
  page,
  filter,
}) => {
  return instance.get(
    `/content/${contentId}/cases/filter?projectId=${projectId}&page=${page}&${filter}`,
  );
};

export const postverifyInvitationCode = ({ verificationCode, userId }) => {
  return instance.post(`/company/verify`, {
    verificationDto: { verificationCode, userId },
  });
};
