import styled from "styled-components";
import TButton from "../guides/button/TButton";
import { colors } from "@/styles/color";
import { body14 } from "@/styles/typography";
import RightArrow from "@/assets/icons/18px/rightArrow.svg";

export default function NewUserModalContent({
  openEnterTeamModal,
  closeModal,
  openPlanModal,
}: {
  openEnterTeamModal: () => void;
  closeModal: () => void;
  openPlanModal: () => void;
}) {
  const openEnterTeamAction = () => {
    openEnterTeamModal();
    closeModal();
  };

  const openPlanAction = () => {
    openPlanModal();
    closeModal();
  };

  return (
    <StyledNewUserModalContent>
      <div className="title">회원가입이 완료되었습니다</div>
      <div className="info">사용할 요금제를 선택한 후 서비스를 시작해보세요</div>

      <TButton
        text=""
        $bgcolor="white"
        $textcolor="black"
        onClick={openEnterTeamAction}
        borderColor={colors.neutral_g3}
        prepend={
          <div className="btn-content">
            <div className="btn-title">팀 코드 입력</div>
            <div className="btn-caption">
              이메일로 초대받은 팀 코드를 입력하여 팀에 합류하세요
            </div>
          </div>
        }
        append={
          <img src={RightArrow} style={{ filter: "brightness(100%) invert(100%)" }} />
        }
      />

      <TButton
        text=""
        $bgcolor="white"
        $textcolor="black"
        borderColor={colors.neutral_g3}
        onClick={openPlanAction}
        prepend={
          <div className="btn-content">
            <div className="btn-title">요금제 선택</div>
            <div className="btn-caption">
              <span style={{ fontWeight: "bold" }}>3개월 무료 프로모션</span>이 진행
              중입니다
            </div>
          </div>
        }
        append={
          <img src={RightArrow} style={{ filter: "brightness(100%) invert(100%)" }} />
        }
      />
    </StyledNewUserModalContent>
  );
}

const StyledNewUserModalContent = styled.div`
  min-width: 519px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  .title {
    font-weight: 700;
    font-size: 24px;
    margin: 20px 0;
  }
  .info {
    font-size: 14px;
    font-weight: 400;
    background-color: ${colors.bg_con};
    border-radius: 12px;
    width: 100%;
    text-align: center;
    padding: 20px;
  }
  button {
    display: flex;
    justify-content: space-between;
    padding: 14px 20px;
    .btn-content {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 10px;
      .btn-title {
        font-size: 16px;
        font-weight: 600;
      }
      .btn-caption {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .caption {
    font-size: ${body14};
    color: ${colors.neutral_g4};
  }
`;
