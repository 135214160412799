import leftArrow from "@/assets/icons/18px/leftArrow.svg";
import ModalPortal from "@/components/modal/ModalPortal";
import { colors } from "@/styles/color";
import { ModalComponentProps } from "@/types/components/modalProps";
import React from "react";
import styled from "styled-components";

interface StyledModalComponentProps {
  backgroundColor?: string;
  color?: string;
}

const DimmedModalComponent: React.FC<ModalComponentProps & StyledModalComponentProps> = ({
  isOpened,
  closeModal,
  backAction,
  content,
  eraseCloseIcon,
  backgroundColor,
  color,
}: ModalComponentProps & StyledModalComponentProps) => {
  if (!isOpened) return null;

  return (
    <ModalPortal>
      <StyledModalComponent backgroundColor={backgroundColor} color={color}>
        <div className="modal">
          <div className="modal-content">
            {eraseCloseIcon ? null : backAction ? (
              <button className="back-action" onClick={backAction}>
                <img src={leftArrow} alt="뒤로 가기" />
              </button>
            ) : (
              <span className="close" onClick={closeModal}>
                &times;
              </span>
            )}
            {content}
          </div>
        </div>
      </StyledModalComponent>
    </ModalPortal>
  );
};

const StyledModalComponent = styled.div<StyledModalComponentProps>`
  overflow-y: hidden;

  .modal {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${colors.bg_con};
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: ${({ backgroundColor }) => backgroundColor || "white"};
    box-shadow: 0px 10px 20px 0px rgba(17, 17, 136, 0.1);
    padding: 20px;
    border-radius: 18px;
    position: relative;
    overflow: hidden;
  }

  .close {
    position: absolute;
    top: 12px;
    right: 18px;
    cursor: pointer;
    font-size: 30px;
    color: ${({ color }) => color || "#888888"};
    &:hover {
      color: black;
    }
  }

  .back-action {
    position: absolute;
    top: 20px;
    left: 21px;
    cursor: pointer;
    background: none;
    border: none;
    padding: 0;
    img {
      width: 18px;
      height: 18px;
      filter: brightness(100%) invert(100%);
    }
  }
`;

export default DimmedModalComponent;
