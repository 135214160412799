import { colors } from "@/styles/color";
import styled from "styled-components";
import TButton from "../guides/button/TButton";

interface MarketingModalComponentProps {
  closeModal: () => void;
}
const MaketingModal: React.FC<MarketingModalComponentProps> = ({
  closeModal,
}: MarketingModalComponentProps) => {
  return (
    <StyledMarketingModalComponent>
      <div className="title">혜택정보 수신 동의</div>
      <div className="content">
        Tax Canvas는 개인정보보호법 등에 관한 법률 등 관계법령에 따라 광고성 정보를
        전송하기 위해 수신자의 사전 수신동의를 받고 있으며, 수신자의 마케팅 정보 수신
        동의여부를 정기적으로 확인합니다. 동의하지 않을 경우, 서비스 소개 및 권유 등
        이용목적에 따른 혜택의 제한이 있을 수 있습니다. 그 밖에 선택적 사항에 대한 금융
        거래와 관련된 불이익은 없습니다.
        <br />
        <br />
        전송 방법
        <br />
        수신자의 핸드폰 문자메세지(SMS), E-mail 등을 통해 전달될 수 있습니다.
        <br />
        <br />
        전송내용
        <br />
        발송되는 마케팅 정보는 수신자에게 Tax Canvas가 운영하는 서비스에서 제공하는 혜택
        정보, 각종 이벤트 및 신규 서비스 안내 등 광고성 정보로 관련 법의 규정을 준수하여
        발송됩니다. 단, 광고성 정보 이외 의무적으로 안내되어야 하는 정보성 내용은 수신동의
        여부와 무관하게 제공됩니다.
        <br />
        <br />
        철회안내
        <br />
        수신자는 수신 동의 이후에라도 의사에 따라 동의를 철회할 수 있으며, 수신을 동의하지
        않아도 Tax Canvas가 제공하는 기본적인 서비스를 이용할 수 있으나, Tax Canvas의
        마케팅 정보를 수신하지 못할 수 있습니다.
        <br />
        <br />
        수신동의 변경
        <br />
        [설정 &gt; 내 계정] ’마케팅 수신정보 동의’ 선택 해제를 통해 수신동의를 변경할 수
        있습니다.
        <br />
        <br />
        개인정보 활용 상세내용
        <br />
        <table>
          <tr>
            <th>이용하는 개인정보 항목</th>
            <th>보유・이용 목적</th>
          </tr>
          <tr>
            <td>이름, 전화번호, 이메일</td>
            <td>
              고객에 대한 편의 제공, 각종 이벤트 및 신규 서비스 안내, Tax Canvas 및
              제휴업체의 상품·서비스 안내 등 광고성 정보 제공
            </td>
          </tr>
        </table>
        <br />
        <br />
        개인정보 보유 및 이용 기간
        <br />
        동의일로부터 회원 탈퇴 혹은 마케팅 수신동의 해제 시까지
      </div>
      <CustomTButton text={"확인"} onClick={closeModal} />
    </StyledMarketingModalComponent>
  );
};

const StyledMarketingModalComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .title {
    font-size: 24px;
    font-weight: 500;
    margin: 27px;
  }

  .content {
    max-height: 570px;
    overflow-y: scroll;
    width: 760px;
    padding: 25px 30px;
    background-color: ${colors.bg_con};
    border-radius: 12px;

    font-weight: 400;
    font-size: 16px;
    line-height: 140%;

    table {
      width: 100%;
      border-collapse: collapse;
    }
    th,
    td {
      border: 1px solid black;
      padding: 8px;
      text-align: left;
    }
    th {
      white-space: nowrap;
    }
  }
`;

const CustomTButton = styled(TButton)`
  margin-top: 15px;
  font-size: 16px;
  width: 380px;
`;

export default MaketingModal;
