import LeftArrow from "@/assets/icons/18px/LeftArrowComponent";
import RightArrow from "@/assets/icons/18px/RightArrow";
import { colors } from "@/styles/color";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

interface PaginationMeta {
  currentPage: number;
  setCurrentPage: Dispatch<SetStateAction<number>>;
  page: number;
  totalPage: number;
}

interface PaginationProps {
  onClick: (currentPage?: number) => void;
  meta: PaginationMeta;
}

const Pagination = ({ onClick: handleInjectedButtonClick, meta }: PaginationProps) => {
  const { currentPage, setCurrentPage, page, totalPage } = meta;

  const currentPageIndex = Math.ceil(currentPage / page);

  const firstPage = (currentPageIndex - 1) * page + 1;
  const lastPage = currentPageIndex * page;

  const pageLength = lastPage > totalPage ? totalPage - firstPage + 1 : page;

  const pages = Array.from(
    {
      length: pageLength,
    },
    (_, index) => firstPage + index,
  );

  const prevButtonDisabled = currentPageIndex === 1;

  const nextButtonDisabled = lastPage >= totalPage;

  const handlePrevButtonClick = () => {
    const prevPage = firstPage - page;
    setCurrentPage(prevPage);
    handleInjectedButtonClick(prevPage);
  };

  const handlePageClick = (event) => {
    const newPage = Number(event.target.id);
    setCurrentPage(newPage);
    handleInjectedButtonClick(newPage);
  };

  const handleNextButtonClick = () => {
    const nextPage = currentPageIndex * page + 1;
    setCurrentPage(nextPage);
    handleInjectedButtonClick(nextPage);
  };

  return (
    <StyledPagination>
      {totalPage > 5 && (
        <StyledArrowButton onClick={handlePrevButtonClick} disabled={prevButtonDisabled}>
          <LeftArrow
            color={prevButtonDisabled ? colors.neutral_g3 : colors.primary_main}
          />
        </StyledArrowButton>
      )}
      {pages.map((page) => {
        return (
          <li key={page}>
            <StyledPaginationButton
              id={page}
              onClick={handlePageClick}
              isClicked={currentPage === page}
            >
              {page}
            </StyledPaginationButton>
          </li>
        );
      })}
      {totalPage > 5 && (
        <StyledArrowButton onClick={handleNextButtonClick} disabled={nextButtonDisabled}>
          <RightArrow
            color={nextButtonDisabled ? colors.neutral_g3 : colors.primary_main}
          />
        </StyledArrowButton>
      )}
    </StyledPagination>
  );
};

export default Pagination;

const StyledPagination = styled.ol`
  display: flex;
  justify-content: center;
  height: 55px;
  gap: 11px;
`;

const StyledPaginationButton = styled.button<{ id: number; isClicked: boolean }>`
  border: none;
  background-color: transparent;
  height: 55px;

  cursor: pointer;

  font-size: 16px;
  color: ${({ isClicked }) => (isClicked ? colors.primary_main : colors.neutral_g3)};
  padding-bottom: 5px;
`;

const StyledArrowButton = styled.button`
  height: 55px;
  background-color: transparent;
  border: none;

  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
`;
