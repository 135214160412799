import React, { useState } from "react";
import { colors } from "@/styles/color";
import styled from "styled-components";
import { boxShadow3 } from "@/styles/shadow";
import { body16 } from "@/styles/typography";
import DimmedModalComponent from "../guides/modal/DimmedModal";
import { putUserPosition } from "@/utils/user";
import { useNavigate } from "react-router-dom";
import SpinnerComponent from "../guides/spinner/Spinner";

interface EnterCompanyPositionModalProps {
  isOpened: boolean;
  closeModal: () => void;
  makeBlackText?: boolean;
  backAction: () => void;
}

export default function EnterCompanyPositionModal({
  isOpened,
  closeModal,
  makeBlackText = false,
  backAction,
}: EnterCompanyPositionModalProps) {
  const [error, setError] = useState("");
  const [formData, setFormData] = useState({
    department: "",
    position: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleSubmit = async () => {
    if (!formData.position) {
      setError("직급 및 직위를 입력해주세요");
    }

    if (!formData.department) {
      setError("조직 및 부서를 입력해주세요");
    }

    try {
      setIsLoading((prev) => !prev);

      const { data } = await putUserPosition({
        userPositionRequest: {
          ...formData,
          id: JSON.parse(localStorage.getItem("user")).id,
        },
      });

      localStorage.setItem("user", JSON.stringify(data.result));

      setIsLoading((prev) => !prev);

      navigate("/");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {isLoading && <SpinnerComponent />}
      <DimmedModalComponent
        closeModal={closeModal}
        isOpened={isOpened}
        backAction={backAction}
        content={
          <EnterCompanyPositionModalContent makeBlackText={makeBlackText}>
            <h1 className="title">직책 입력</h1>
            <div className="command-wrap">
              <p>정보를 입력한 후 서비스를 이용해 보세요!</p>
            </div>
            <div className="inputWrap">
              <label htmlFor="department">조직 및 부서</label>
              <input
                id="department"
                type="text"
                placeholder="소속 조직 및 부서를 입력해주세요"
                value={formData.department}
                onChange={handleChange}
              />
            </div>
            <div className="inputWrap">
              <label htmlFor="position">직급 및 직위</label>
              <input
                id="position"
                type="text"
                placeholder="회원님의 직급 및 직위를 입력해주세요"
                value={formData.position}
                onChange={handleChange}
              />
            </div>
            <button onClick={handleSubmit}>확인</button>
            {error && <p className="error-message">{error}</p>}
          </EnterCompanyPositionModalContent>
        }
      />
    </>
  );
}

const EnterCompanyPositionModalContent = styled.div<{ makeBlackText?: boolean }>`
  width: 530px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
  }

  .command-wrap {
    border-radius: 12px;
    background-color: ${colors.bg_con};
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }

  button {
    ${boxShadow3}
    border: none;
    background-color: ${colors.primary_main};
    border-radius: 12px;
    cursor: pointer;
    height: 47px;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: ${colors.white};
    margin: 0 auto;
  }

  .inputWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  label {
    font-size: 16px;
    font-weight: 500;
  }

  input {
    ${body16}
    color: ${(props) => (props.makeBlackText ? colors.black : colors.neutral_g3)};
    width: 100%;
    padding: 15px 12px 14px 12px;
    outline: none;
    border: 1px solid #eee;
    background-color: transparent;
    border-radius: 12px;
  }

  input:focus {
    color: ${colors.black};
  }

  input::placeholder {
    color: ${colors.neutral_g3};
  }

  .error-message {
    color: ${colors.secondary_main};
  }
`;
