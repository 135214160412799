import { ChipComponentProps } from "@/types/components/chipProps";
import styled from "styled-components";

const ChipComponent: React.FC<ChipComponentProps> = (props: ChipComponentProps) => {
  return (
    <StyledChipComponent $bgcolor={props.$bgcolor} bgBorderColor={props.bgBorderColor}>
      {props.content}
    </StyledChipComponent>
  );
};

const StyledChipComponent = styled.div<{
  bgBorderColor?: string;
  $bgcolor?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  //padding: 1px 6px;
  border-radius: 50%;
  background-color: ${(props) => props.$bgcolor};
  border: ${(props) =>
    props.bgBorderColor ? `1px solid ${props.bgBorderColor}` : `1px solid transparent`};
`;

export default ChipComponent;
