import React, { useEffect } from "react";
import styled from "styled-components";
import { colors } from "@/styles/color";
import { body16, body18, title20 } from "@/styles/typography";
import TButton from "@/components/guides/button/TButton";
import { allowScroll, preventScroll } from "@/utils/modal";
import { deleteProjectContent } from "@/utils/coreService";

interface ProjectContentDeleteModalProps {
  projectId: string;
  contentId: string;
  onDelete: () => void;
  handleModal: (isOpen: boolean) => void;
}

const ProjectContentDeleteModal: React.FC<ProjectContentDeleteModalProps> = ({
  projectId,
  contentId,
  onDelete,
  handleModal,
}) => {
  const handleDelete = async () => {
    await deleteProjectContent({ projectId, contentId: Number(contentId) });

    await onDelete();
    handleModal(false);
  };

  useEffect(() => {
    const prevScrollY = preventScroll();
    return () => {
      allowScroll(prevScrollY);
    };
  }, []);

  return (
    <StyledProjectContentDeleteModal>
      <div className="modal-header">
        <h2>안내</h2>
      </div>
      <div className="modal-body">
        삭제하시고자 하는 쟁점은 AI 분석 결과가 존재합니다. <br />
        쟁점 삭제 시 사용한 토큰과 결과는 복원할 수 없습니다. <br />
        정말 삭제하시겠습니까?
      </div>
      <div className="modal-footer">
        <TButton
          text="삭제"
          fontSize={body16}
          $bgcolor={colors.primary_main}
          $textcolor="white"
          onClick={handleDelete}
        />
      </div>
    </StyledProjectContentDeleteModal>
  );
};

const StyledProjectContentDeleteModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 99;
  background: white;
  width: 530px;
  padding: 25px 15px;

  .modal-header {
    text-align: center;
    h2 {
      ${title20}
      margin: 0;
    }
    .close {
      cursor: pointer;
    }
  }

  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 500px;
    height: 175px;
    background-color: ${colors.bg_con};
    margin-top: 20px;
    text-align: center;
    ${body18}
  }

  .modal-project-name {
    color: ${colors.primary_main};
  }

  .modal-footer {
    width: 100%;
    margin-top: 20px;
  }
`;

export default ProjectContentDeleteModal;
