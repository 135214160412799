import { boxShadow } from "@/styles/shadow";
import styled from "styled-components";
import { colors } from "@/styles/color";
import { body14, body16 } from "@/styles/typography";
import ChipComponent from "../guides/chip/Chip";
import checkIcon from "@/assets/icons/16px/check.svg";
import checkIconRed from "@/assets/icons/16px/checkRed.svg";
import copyIcon from "@/assets/icons/24px/copy.svg";
import AutoResizeTextarea from "../guides/textarea/AutoResizeTextarea";
import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useState } from "react";

interface RelatedCases {
  caseId: string;
  title: string;
  category: string;
  status: string | null;
  caption: string;
  accuracy: number;
}

interface AnalyzedDataCardProps {
  onDeleteModal: () => void;
  onRelatedCasesClick: (caseId: string) => void;
  projectId: string;
  detailId: string;
  factualRelation: string;
  keyIssues: string;
  analysisResult: string;
  relatedCases: RelatedCases[];
}

export default function AnalyzedDataCard({
  onDeleteModal: handleDeleteModal,
  onRelatedCasesClick: handleRelatedCasesClick,
  projectId,
  detailId,
  factualRelation,
  keyIssues,
  analysisResult,
  relatedCases,
}: AnalyzedDataCardProps) {
  const [, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    alert("분석결과가 복사되었습니다.");
    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  return (
    <StyledCard>
      <div className="wrapper">
        <div className="top">
          <ChipComponent
            $bgcolor={colors.secondary_sub}
            content={
              <img
                style={{ padding: "4px", width: "25px", height: "25px" }}
                src={checkIconRed}
              />
            }
          />
          <h2 className="title">입력한 사실관계</h2>
          <button type="button" className="delete" onClick={handleDeleteModal}>
            <span>이 페이지 비우기</span>
          </button>
        </div>
        <div className="textarea-container">
          <AutoResizeTextarea
            rows={5}
            maxLength={500}
            placeholder="- 1993. 4. ○○구 ○○동 소재 아파트 취득(1세대1주택 비과세 요건 갖춤)
- 1998. 3. 배우자와 자녀 2명 취학(대학교) 관계로 미국 출국
- 2001. 3. ○○구 ○○동 소재 아파트 취득
- 2002. 5. ○○구 ○○동 소재 아파트 재건축 사업승인 받음(현재 재건축 진행 중)
- 배우자는 2005. 3. 국내에 거소신고 필한 상태이며 자녀 2명은 현재까지 미국에 거주하고 있음."
            name="fact"
            id="factTA"
            disabled
            value={factualRelation}
          />
        </div>
        <div className="top">
          <ChipComponent
            $bgcolor={colors.secondary_sub}
            content={
              <img
                style={{ padding: "4px", width: "25px", height: "25px" }}
                src={checkIconRed}
              />
            }
          />
          <h2 className="title">입력한 쟁점</h2>
        </div>
        <div className="textarea-container">
          <AutoResizeTextarea
            maxLength={800}
            placeholder="1993. 4. 취득한 ○○구 ○○동 소재 아파트 양도 시 1세대 1주택 비과세 적용여부"
            name="issue"
            id="issueTA"
            rows={1}
            disabled
            value={keyIssues}
          />
        </div>
        <div className="top">
          <ChipComponent
            $bgcolor={colors.bg_con}
            content={
              <img
                style={{ padding: "4px", width: "25px", height: "25px" }}
                src={checkIcon}
              />
            }
          />
          <div className="analysis-result">
            <h2 className="title">분석 결과</h2>
            <CopyToClipboard text={analysisResult} onCopy={handleCopy}>
              <CopyButton>
                <img src={copyIcon} />
              </CopyButton>
            </CopyToClipboard>
          </div>
        </div>
        <StyledResultCard>{analysisResult}</StyledResultCard>
      </div>
      <div>
        <div className="top">
          <ChipComponent
            $bgcolor={colors.bg_con}
            content={
              <img
                style={{ padding: "4px", width: "25px", height: "25px" }}
                src={checkIcon}
              />
            }
          />
          <h2 className="title">주요 예규/판례</h2>
          <p className="detail-description">
            <div>쟁점 연관성 상위 4개 예규/판례 입니다.</div>
            <div>더 많은 예규 및 판례는 하단의 더보기에서 확인하실 수 있습니다.</div>
          </p>
        </div>
        <div className="data-card-container">
          {relatedCases.map((data, index) => (
            <button type="button" onClick={() => handleRelatedCasesClick(data.caseId)}>
              <StyledDataCard key={index}>
                <CategoryWrap>
                  <PrimaryCategory>{data.category}</PrimaryCategory>
                  {data.status && <SubCategory>{data.status}</SubCategory>}
                  <PercentCategory>유사도 {data.accuracy}%</PercentCategory>
                </CategoryWrap>
                <div className="subtitle2">
                  {data.title.length <= 50 ? data.title : data.title.slice(0, 50) + "..."}
                </div>
                <div className="caption">
                  {data.caption.length <= 60
                    ? data.caption
                    : data.caption.slice(0, 60) + "..."}
                </div>
              </StyledDataCard>
            </button>
          ))}
        </div>
      </div>
      <ButtonWrapper>
        <MoreButton>
          <Link to={`/project/${projectId}/detail/${detailId}`}>더보기 &gt;</Link>
        </MoreButton>
      </ButtonWrapper>
    </StyledCard>
  );
}

const StyledCard = styled.div`
  position: relative;
  padding: 30px 30px 10px 30px;
  border-radius: 12px;
  background-color: white;
  ${boxShadow}
  h2 {
    font-size: 24px;
    font-weight: 500;
    color: ${colors.black};
  }
  .wrapper {
    margin-bottom: 25px;
    .top {
      margin-bottom: 10px;

      .analysis-result {
        display: flex;

        button {
          margin-bottom: 10px;
        }
      }
    }

    button {
      cursor: pointer;
    }
  }
  .delete {
    padding: 0px;
    width: fit-content;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 29px;
    top: 35px;
    color: ${colors.secondary_main};
    font-size: 12px;
    border-bottom: 1px solid ${colors.secondary_main};
  }
  .textarea-container {
    margin-bottom: 25px;
  }

  .data-card-container {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;

    button {
      width: 365px;
      border: none;
      background-color: transparent;

      &:nth-child(2n) {
        margin-left: 10px;
      }
    }
  }
  .detail-description {
    display: flex;
    flex-direction: column;
    gap: 2px;
    font-size: 12px;
    font-weight: 400;
    color: ${colors.primary_main};
    margin: auto 10px auto auto;
    text-align: right;
  }
`;
const StyledDataCard = styled.div`
  width: 365px;
  height: 178px;
  border-radius: 12px;
  border: ${colors.neutral_g2} 1px solid;
  cursor: pointer;

  &:hover {
    border-radius: 12px;
    border: ${colors.primary_main} 1px solid;
  }

  flex-grow: 1;
  margin: 0 auto;
  padding: 20px;
  .subtitle2 {
    width: 334px;
    height: 48px;
    text-align: left;
    font-weight: bold;
    font-size: ${body16};
    margin-bottom: 10px;
  }
  .caption {
    width: 334px;
    height: 42px;
    text-align: left;
    font-size: ${body14};
    color: ${colors.neutral_g4};
  }
`;
const StyledResultCard = styled.div`
  border-radius: 12px;
  background-color: ${colors.primary_sub};
  font-size: 18px;
  padding: 24px 20px;
  line-height: 30px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 5px;
`;
const MoreButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  padding: 20px;
  width: 95px;
  white-space: nowrap;
  margin: 0 auto;

  a {
    color: ${colors.black};
    text-decoration: none;
  }
`;

const CategoryWrap = styled.div`
  display: flex;
  gap: 13px;
  margin-bottom: 13px;
`;

const PrimaryCategory = styled.div`
  color: ${colors.primary_main};
  background-color: ${colors.primary_sub};
  font-size: 14px;
  font-weight: 400;
  padding: 5px 9px;
  border-radius: 8px;
`;

const SubCategory = styled.div`
  color: ${colors.secondary_main};
  background-color: ${colors.secondary_sub};
  font-size: 14px;
  font-weight: 400;
  padding: 5px 9px;
  border-radius: 8px;
`;

const PercentCategory = styled.div`
  color: ${colors.secondary_main};
  background-color: ${colors.white};
  border: 1px solid ${colors.secondary_main};
  font-size: 14px;
  font-weight: 400;
  padding: 5px 9px;
  border-radius: 8px;
`;

const CopyButton = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
