import { useRef, useState } from "react";

type ElementType<T> = T extends Array<infer U> ? U : never;

type Optional<T> = {
  [K in keyof T]?: T[K];
};

interface CategorySelectProps<T> {
  categoryTitle: string;
  categories: Array<ElementType<T>>;
}

interface UseCategorySelectProps<T extends Array<ElementType<T>>> {
  categoryTitle: string; // TODO: Category title type can infer exactly
  initialSelectedCategory: T;
  onSelect?: ({ categoryTitle, categories }: Optional<CategorySelectProps<T>>) => void;
}

function useCategorySelect<T extends Array<ElementType<T>>>({
  categoryTitle,
  initialSelectedCategory,
  onSelect,
}: UseCategorySelectProps<T>) {
  const [selectedCategory, setSelectedCategory] = useState<Array<ElementType<T>>>(
    initialSelectedCategory,
  );

  const defaultCategory = initialSelectedCategory[0];

  const currentSelectedCategory = useRef<ElementType<T>>(defaultCategory);

  const isSelected = (category: ElementType<T>) => {
    return selectedCategory.includes(category);
  };

  const isDefaultCategorySelected = (category: ElementType<T>) => {
    return selectedCategory.length > 0 && category === defaultCategory;
  };

  const isSelectedWithDefaultCategory = () => {
    return selectedCategory.includes(defaultCategory);
  };

  const deselectCategory = (category: ElementType<T>) => {
    return selectedCategory.filter((aCategory) => aCategory !== category);
  };

  const handleCategorySelect = (category: ElementType<T>) => {
    currentSelectedCategory.current = category;

    if (isSelected(category)) {
      const deselectedCategory = deselectCategory(category);

      if (deselectedCategory.length === 0) {
        onSelect({ categoryTitle, categories: [defaultCategory] });

        return setSelectedCategory([defaultCategory]);
      }

      onSelect({ categoryTitle, categories: deselectedCategory });

      return setSelectedCategory(deselectedCategory);
    }

    if (isDefaultCategorySelected(category)) {
      onSelect({ categoryTitle, categories: [defaultCategory] });

      return setSelectedCategory([defaultCategory]);
    }

    if (isSelectedWithDefaultCategory()) {
      const deselectedCategory = deselectCategory(defaultCategory);

      onSelect({ categoryTitle, categories: [...deselectedCategory, category] });

      return setSelectedCategory([...deselectedCategory, category]);
    }

    onSelect({ categoryTitle, categories: [...selectedCategory, category] });

    return setSelectedCategory((prevSelectedCategory) => [
      ...prevSelectedCategory,
      category,
    ]);
  };

  return {
    currentSelectedCategory: currentSelectedCategory.current,
    selectedCategory,
    setSelectedCategory,
    handleCategorySelect,
    isSelected,
  };
}

export default useCategorySelect;
