import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@/styles/color";
import { body16, title14, title20 } from "@/styles/typography";
import TButton from "@/components/guides/button/TButton";

const SERVICE_CODE = import.meta.env.VITE_SERVICE_CODE;

interface BlockingModalContentProps {
  handleModal: (isOpen: boolean) => void;
}

const BlockingModalContent: React.FC<BlockingModalContentProps> = ({ handleModal }) => {
  const [serviceCode, setServiceCode] = useState("");
  const [error, setError] = useState("");

  const handleClick = async () => {
    if (serviceCode === SERVICE_CODE) {
      localStorage.setItem("service-code", serviceCode);
      handleModal(false);
    } else {
      setError("잘못된 서비스 코드 입니다.");
    }
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleClick();
    }
  };

  return (
    <StyledBlockingModalContent>
      <div className="modal-header">
        <h2>서비스 코드 입력</h2>
      </div>
      <div className="modal-body">
        <input
          type="text"
          value={serviceCode}
          onChange={(e) => setServiceCode(e.target.value)}
          onKeyDown={handleEnter}
        />
        <div className="error-message">{error}</div>
      </div>
      <div className="modal-footer">
        <TButton
          text="확인"
          fontSize={body16}
          $bgcolor={colors.primary_main}
          $textcolor="white"
          onClick={handleClick}
        />
      </div>
    </StyledBlockingModalContent>
  );
};

const StyledBlockingModalContent = styled.div`
  z-index: 99;
  min-width: 370px;
  background: white;
  width: 530px;
  padding: 25px 15px;

  .modal-header {
    h2 {
      text-align: center;
      ${title20}
      margin: 0;
    }
    .close {
      cursor: pointer;
    }
  }

  .modal-body {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    input {
      width: 100%;
      padding: 10px;
      height: 47px;
      font-size: 16px;
      border: 1px solid ${colors.neutral_g2};
      border-radius: 8px;
      ${body16}
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }

  .error-message {
    ${title14}
    margin-top: 10px;
    color: red;
  }
`;

export default BlockingModalContent;
