import React, { useState, useRef } from "react";
import styled from "styled-components";
import teamsIcon from "@/assets/icons/20px/teamsIcon.svg";
import optionsIcon from "@/assets/icons/30px/option_btn.svg";
import TButton from "@/components/guides/button/TButton";
import { body14 } from "@/styles/typography";
import { colors } from "@/styles/color";
import ProjectMenu from "./ProjectMenu";
import { Link } from "react-router-dom";

interface ListItemProps {
  id: string;
  text: string;
  isShared: boolean;
  onChangeName: () => void;
  projectLength: number;
}

const ListItem: React.FC<ListItemProps> = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <StyledListItem>
      <Link to={`/project/${props.id}`} key={props.id}>
        <div className="text">{props.text}</div>
      </Link>
      <div className="button-container" ref={buttonRef}>
        {props.isShared && (
          <TButton $bgcolor="transparent" text="" prepend={<img src={teamsIcon} />} />
        )}
        <TButton
          $bgcolor="transparent"
          text=""
          prepend={<img src={optionsIcon} />}
          onClick={() => handleToggleMenu()}
        />
      </div>
      <ProjectMenu
        projectId={props.id}
        projectName={props.text}
        projectLength={props.projectLength}
        isOpen={isMenuOpen}
        onClose={closeMenu}
        anchorRef={buttonRef}
        onChangeName={props.onChangeName}
      />
    </StyledListItem>
  );
};

const StyledListItem = styled.div`
  color: ${colors.neutral_g4};
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  position: relative;

  a {
    width: 171px;
    height: 100%;
    padding: 8px 5px;
    color: ${colors.neutral_g4};
    text-decoration: none;

    &:visited {
      text-decoration: none;
    }
  }

  &:hover {
    background-color: ${colors.neutral_g2};
    border-radius: 6px;
  }

  img {
    width: 20px;
  }
  .text {
    flex-grow: 1;
    font-size: ${body14};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .button-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: absolute;
    right: 0px;
  }
`;

export default ListItem;
