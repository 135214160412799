import { css } from "styled-components";

export const boxShadow = css`
  box-shadow: 0px 10px 20px 0px rgba(17, 17, 136, 0.1);
`;

export const boxShadow2 = css`
  box-shadow: 0px 10px 20px 0px rgba(50, 50, 194, 0.1);
`;

// snb btn shadow
export const boxShadow3 = css`
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;