import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import kakaoSvg from "@/assets/icons/36px/kakao.svg";
import IconButtonComponent from "../guides/button/IconButton";

const KakaoAuthenticationComponent: React.FC = () => {
  const kakaoLogin: MouseEventHandler<HTMLDivElement> = () => {
    alert("현재 버전에서는 구글과 네이버 로그인만 지원합니다");
  };

  return (
    <StyledKakaoAuthenticationComponent>
      <IconButtonComponent
        action={kakaoLogin}
        text={"카카오로 시작하기"}
        icon={<img src={kakaoSvg} alt="Kakao" />}
      />
    </StyledKakaoAuthenticationComponent>
  );
};

const StyledKakaoAuthenticationComponent = styled.div``;

export default KakaoAuthenticationComponent;
