import ChipComponent from "@/components/guides/chip/Chip";
import checkIcon from "@/assets/icons/16px/check.svg";
import { colors } from "@/styles/color";
import { boxShadow } from "@/styles/shadow";
import { body14, body16, title24, title38 } from "@/styles/typography";
import styled from "styled-components";
import { useEffect, useRef, useState } from "react";
import { getRelatedCases } from "@/utils/detail";
import { Link, useNavigate, useParams } from "react-router-dom";
import Pagination from "@/components/guides/Pagination";
import LeftArrow from "@/assets/icons/18px/LeftArrowComponent";
import SearchChip from "@/components/guides/chip/SearchChip";
import DownArrow from "@/assets/icons/20px/DownArrow";
import useCategorySelect from "@/hooks/useCategorySelect";
import {
  Appeal,
  Court,
  Document,
  InspectionRequest,
  Judgement,
  StatusCategory,
  Taxation,
  TrialRequest,
} from "@/types/taxcanvas";
import {
  APPEAL,
  COURT,
  DOCUMENT,
  INSPECTION_REQUEST,
  JUDGEMENT,
  TAXATION,
  TRIAL_REQUEST,
} from "@/utils/consts";
import DetailModal from "@/components/modal/DetailModal";
import Tune from "@/assets/icons/20px/Tune";
import SpinnerComponent from "@/components/guides/spinner/Spinner";
import { getContentRelatedCasesWithFilter } from "@/utils/coreService";

interface RelatedCases {
  caseId: string;
  title: string;
  category: string;
  status: string | null;
  caption: string;
  accuracy: number;
}

interface PaginationData {
  page: number;
  take: number;
  totalItem: number;
  totalPage: number;
}

interface IsStatusCategorySelected {
  selectedDocumentCategory: Array<Document>;
  statusCategory: StatusCategory;
}

export default function DetailPage() {
  const { projectId, detailId: contentId } = useParams();

  const [relatedCases, setRelatedCases] = useState<RelatedCases[] | undefined>();

  const [paginationData, setPaginationData] = useState<PaginationData | undefined>();

  const [detailTitle, setDetailTitle] = useState("");

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [isActiveDropdown, setIsActiveDropdown] = useState(true);

  const [isLoading, setIsLoading] = useState(true);

  const isStatusCategorySelected = ({
    selectedDocumentCategory,
    statusCategory,
  }: IsStatusCategorySelected) => {
    if (selectedDocumentCategory.includes("전체")) {
      return true;
    }
    return selectedDocumentCategory.includes(statusCategory);
  };

  const documentCategory = useRef(["전체"]);

  const documentCategoryFilter = useRef("category=전체");

  const statusCategoryFilter = useRef({
    판례: "판례=전체",
    과세적부: "과세적부=전체",
    이의신청: "이의신청=전체",
    심사청구: "심사청구=전체",
    심판청구: "심판청구=전체",
    헌재: "헌재=전체",
  });

  const createSearchCategoryFilter = () => {
    const totalStatusCategoryFilter = Object.values(statusCategoryFilter.current)
      .filter((statusCategory) => statusCategory)
      .join("&");

    return `${documentCategoryFilter.current}&${totalStatusCategoryFilter}`;
  };

  const handleDocumentCategoryButtonClick = async ({ categories }) => {
    const STATUS_CATEGORY = [
      "판례",
      "과세적부",
      "이의신청",
      "심사청구",
      "심판청구",
      "헌재",
    ];

    STATUS_CATEGORY.forEach((statusCategory) => {
      if (!categories.includes(statusCategory) && !categories.includes("전체")) {
        statusCategoryFilter.current[statusCategory] = "";
      } else if (
        currentSelectedDocumentCategory === statusCategory &&
        categories.includes(statusCategory)
      ) {
        statusCategoryFilter.current[statusCategory] = `${statusCategory}=전체`;
      }
    });

    const documentCategoryQueries = categories
      .map((category) => {
        return `category=${category}`;
      })
      .join("&");

    documentCategory.current = categories;
    documentCategoryFilter.current = documentCategoryQueries;

    const searchCategoryFilter = createSearchCategoryFilter();

    setIsLoading((prev) => !prev);

    const {
      data: {
        data: { title, relatedCases },
        meta,
      },
    } = await getContentRelatedCasesWithFilter({
      projectId,
      contentId,
      page: 1,
      filter: searchCategoryFilter,
    });

    setIsLoading((prev) => !prev);
    setDetailTitle(title);
    setRelatedCases(relatedCases);
    setPaginationData(meta);
    setCurrentPage(meta.page);

    return;
  };

  const handleStatusCategoryButtonClick = async ({ categoryTitle, categories }) => {
    const statusCategoryQueries = categories
      .map((category) => {
        return `${categoryTitle}=${category}`;
      })
      .join("&");

    statusCategoryFilter.current[categoryTitle] = statusCategoryQueries;

    const searchCategoryFilter = createSearchCategoryFilter();

    setIsLoading((prev) => !prev);

    const {
      data: {
        data: { title, relatedCases },
        meta,
      },
    } = await getContentRelatedCasesWithFilter({
      projectId,
      contentId,
      page: 1,
      filter: searchCategoryFilter,
    });

    setIsLoading((prev) => !prev);
    setDetailTitle(title);
    setRelatedCases(relatedCases);
    setPaginationData(meta);
    setCurrentPage(meta.page);

    return;
  };

  const {
    currentSelectedCategory: currentSelectedDocumentCategory,
    selectedCategory: selectedDocumentCategory,
    handleCategorySelect: handleDocumentCategorySelect,
    isSelected: isDocumentCategorySelected,
  } = useCategorySelect<Array<Document>>({
    categoryTitle: "문서유형",
    initialSelectedCategory: ["전체"],
    onSelect: handleDocumentCategoryButtonClick,
  });

  const {
    setSelectedCategory: setSelectedJudgementCategory,
    handleCategorySelect: handleJudgementCategorySelect,
    isSelected: isJudgementCategorySelected,
  } = useCategorySelect<Array<Judgement>>({
    categoryTitle: "판례",
    initialSelectedCategory: ["전체"],
    onSelect: handleStatusCategoryButtonClick,
  });

  const {
    setSelectedCategory: setSelectedTaxationCategory,
    handleCategorySelect: handleTaxationCategorySelect,
    isSelected: isTaxationCategorySelected,
  } = useCategorySelect<Array<Taxation>>({
    categoryTitle: "과세적부",
    initialSelectedCategory: ["전체"],
    onSelect: handleStatusCategoryButtonClick,
  });

  const {
    setSelectedCategory: setSelectedAppealCategory,
    handleCategorySelect: handleAppealCategorySelect,
    isSelected: isAppealCategorySelect,
  } = useCategorySelect<Array<Appeal>>({
    categoryTitle: "이의신청",
    initialSelectedCategory: ["전체"],
    onSelect: handleStatusCategoryButtonClick,
  });

  const {
    setSelectedCategory: setSelectedInspectionRequestCategory,
    handleCategorySelect: handleInspectionRequestCategorySelect,
    isSelected: isInspectionRequestSelected,
  } = useCategorySelect<Array<InspectionRequest>>({
    categoryTitle: "심사청구",
    initialSelectedCategory: ["전체"],
    onSelect: handleStatusCategoryButtonClick,
  });

  const {
    setSelectedCategory: setSelectedTrialRequestCategory,
    handleCategorySelect: handleTrialRequestCategorySelect,
    isSelected: isTrialRequestSelected,
  } = useCategorySelect<Array<TrialRequest>>({
    categoryTitle: "심판청구",
    initialSelectedCategory: ["전체"],
    onSelect: handleStatusCategoryButtonClick,
  });

  const {
    setSelectedCategory: setSelectedCourtCategory,
    handleCategorySelect: handleCourtCategorySelect,
    isSelected: isCourtCategorySelected,
  } = useCategorySelect<Array<Court>>({
    categoryTitle: "헌재",
    initialSelectedCategory: ["전체"],
    onSelect: handleStatusCategoryButtonClick,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const serviceCode = localStorage.getItem("service-code");

    if (serviceCode !== import.meta.env.VITE_SERVICE_CODE) {
      navigate("/authentication");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchRelatedCases = async () => {
      const {
        data: {
          data: { title, relatedCases },
          meta,
        },
      } = await getRelatedCases({ projectId, contentId: Number(contentId), page: 1 });

      setIsLoading((prev) => !prev);

      setDetailTitle(title);
      setRelatedCases(relatedCases);
      setPaginationData(meta);
      setCurrentPage(meta.page);
    };

    fetchRelatedCases();
  }, [contentId, projectId]);

  const handlePaginationButtonClick = async (currentPage) => {
    const searchCategoryFilter = createSearchCategoryFilter();

    setIsLoading((prev) => !prev);

    const {
      data: {
        data: { title, relatedCases },
        meta,
      },
    } = await getContentRelatedCasesWithFilter({
      projectId,
      contentId: Number(contentId),
      page: currentPage,
      filter: searchCategoryFilter,
    });

    setIsLoading((prev) => !prev);

    setDetailTitle(title);
    setRelatedCases(relatedCases);
    setPaginationData(meta);
    setCurrentPage(meta.page);
  };

  const isActiveButton = (category: Array<Document>) => {
    const statusCategory = [
      "판례",
      "과세적부",
      "이의신청",
      "심사청구",
      "심판청구",
      "헌재",
      "전체",
    ];

    return category.some((aCategory) => statusCategory.includes(aCategory));
  };

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);

  const [caseId, setCaseId] = useState("");

  const openDetailModal = () => {
    setIsDetailModalOpen(true);
  };

  const closeDetailModal = () => {
    setIsDetailModalOpen(false);
  };

  return (
    <StyledDetailPage>
      {isLoading && <SpinnerComponent />}
      <div className="page-title">세무 분석</div>
      {relatedCases && (
        <StyledIssueContainerCard>
          <div className="top issue-header">
            <ChipComponent
              $bgcolor={colors.bg_con}
              content={
                <img
                  style={{ padding: "4px", width: "25px", height: "25px" }}
                  src={checkIcon}
                />
              }
            />
            <div>
              <div className="title">{detailTitle}</div>
              <div className="subtitle">
                쟁점 연관성 기준 상위 20개씩 확인할 수 있습니다. 다음 20개를 보고싶으시면
                더보기를 눌러주세요.
              </div>
            </div>
          </div>
          <div className="issue-container">
            <StyledDataCardContainer>
              <div className="header-container">
                <Link to={`/project/${projectId}`}>
                  <LeftArrow color={colors.black} />
                </Link>
                <span className="header-title">쟁점 목록</span>
              </div>
              <div className="search-container">
                <div className="search-header">
                  <div className="search-title">검색 결과 조정</div>
                  <Tune />
                </div>
                <div className="search-content">
                  <div className="search-chip-title">문서 유형</div>
                  <div className="search-chip-container">
                    {DOCUMENT.map((category) => {
                      return (
                        <SearchChip
                          key={category}
                          type="main"
                          selected={isDocumentCategorySelected(category)}
                          onClick={() => {
                            const statusCategory = [
                              "판례",
                              "과세적부",
                              "이의신청",
                              "심사청구",
                              "심판청구",
                              "헌재",
                              "전체",
                            ];

                            if (statusCategory.includes(category)) {
                              setIsActiveDropdown(true);
                            }

                            if (
                              category === "판례" &&
                              documentCategory.current.includes("판례")
                            ) {
                              setSelectedJudgementCategory(["전체"]);
                            }

                            if (
                              category === "과세적부" &&
                              documentCategory.current.includes("과세적부")
                            ) {
                              setSelectedTaxationCategory(["전체"]);
                            }

                            if (
                              category === "이의신청" &&
                              documentCategory.current.includes("이의신청")
                            ) {
                              setSelectedAppealCategory(["전체"]);
                            }

                            if (
                              category === "심사청구" &&
                              documentCategory.current.includes("심사청구")
                            ) {
                              setSelectedInspectionRequestCategory(["전체"]);
                            }

                            if (
                              category === "심판청구" &&
                              documentCategory.current.includes("심판청구")
                            ) {
                              setSelectedTrialRequestCategory(["전체"]);
                            }

                            if (
                              category === "헌재" &&
                              documentCategory.current.includes("헌재")
                            ) {
                              setSelectedCourtCategory(["전체"]);
                            }

                            handleDocumentCategorySelect(category);
                          }}
                        >
                          {category}
                        </SearchChip>
                      );
                    })}
                    {isActiveButton(selectedDocumentCategory) && (
                      <button
                        className="search-dropdown-icon"
                        onClick={() => setIsActiveDropdown((prev) => !prev)}
                      >
                        <DownArrow />
                      </button>
                    )}
                  </div>
                </div>
                {isStatusCategorySelected({
                  selectedDocumentCategory,
                  statusCategory: "판례",
                }) &&
                  isActiveDropdown && (
                    <div className="search-content">
                      <div className="search-chip-title">판례</div>
                      <div className="search-chip-container">
                        {JUDGEMENT.map((category) => {
                          return (
                            <SearchChip
                              key={category}
                              type="sub"
                              selected={isJudgementCategorySelected(category)}
                              onClick={() => {
                                handleJudgementCategorySelect(category);
                              }}
                            >
                              {category}
                            </SearchChip>
                          );
                        })}
                      </div>
                    </div>
                  )}
                {isStatusCategorySelected({
                  selectedDocumentCategory,
                  statusCategory: "과세적부",
                }) &&
                  isActiveDropdown && (
                    <div className="search-content">
                      <div className="search-chip-title">과세적부</div>
                      <div className="search-chip-container">
                        {TAXATION.map((category) => {
                          return (
                            <SearchChip
                              key={category}
                              type="sub"
                              selected={isTaxationCategorySelected(category)}
                              onClick={() => {
                                handleTaxationCategorySelect(category);
                              }}
                            >
                              {category}
                            </SearchChip>
                          );
                        })}
                      </div>
                    </div>
                  )}
                {isStatusCategorySelected({
                  selectedDocumentCategory,
                  statusCategory: "이의신청",
                }) &&
                  isActiveDropdown && (
                    <div className="search-content">
                      <div className="search-chip-title">이의신청</div>
                      <div className="search-chip-container">
                        {APPEAL.map((category) => {
                          return (
                            <SearchChip
                              key={category}
                              type="sub"
                              selected={isAppealCategorySelect(category)}
                              onClick={() => {
                                handleAppealCategorySelect(category);
                              }}
                            >
                              {category}
                            </SearchChip>
                          );
                        })}
                      </div>
                    </div>
                  )}
                {isStatusCategorySelected({
                  selectedDocumentCategory,
                  statusCategory: "심사청구",
                }) &&
                  isActiveDropdown && (
                    <div className="search-content">
                      <div className="search-chip-title">심사청구</div>
                      <div className="search-chip-container">
                        {INSPECTION_REQUEST.map((category) => {
                          return (
                            <SearchChip
                              key={category}
                              type="sub"
                              selected={isInspectionRequestSelected(category)}
                              onClick={() => {
                                handleInspectionRequestCategorySelect(category);
                              }}
                            >
                              {category}
                            </SearchChip>
                          );
                        })}
                      </div>
                    </div>
                  )}
                {isStatusCategorySelected({
                  selectedDocumentCategory,
                  statusCategory: "심판청구",
                }) &&
                  isActiveDropdown && (
                    <div className="search-content">
                      <div className="search-chip-title">심판청구</div>
                      <div className="search-chip-container">
                        {TRIAL_REQUEST.map((category) => {
                          return (
                            <SearchChip
                              key={category}
                              type="sub"
                              selected={isTrialRequestSelected(category)}
                              onClick={() => {
                                handleTrialRequestCategorySelect(category);
                              }}
                            >
                              {category}
                            </SearchChip>
                          );
                        })}
                      </div>
                    </div>
                  )}
                {isStatusCategorySelected({
                  selectedDocumentCategory,
                  statusCategory: "헌재",
                }) &&
                  isActiveDropdown && (
                    <div className="search-content">
                      <div className="search-chip-title">헌재</div>
                      <div className="search-chip-container">
                        {COURT.map((category) => {
                          return (
                            <SearchChip
                              key={category}
                              type="sub"
                              selected={isCourtCategorySelected(category)}
                              onClick={() => {
                                handleCourtCategorySelect(category);
                              }}
                            >
                              {category}
                            </SearchChip>
                          );
                        })}
                      </div>
                    </div>
                  )}
              </div>

              {relatedCases && (
                <div className="related-cases-container">
                  {relatedCases.map((data, index) => (
                    <button
                      type="button"
                      onClick={() => {
                        setCaseId(data.caseId);
                        openDetailModal();
                      }}
                    >
                      <StyledDataCard key={index}>
                        <CategoryWrap>
                          <PrimaryCategory>{data.category}</PrimaryCategory>
                          {data.status && <SubCategory>{data.status}</SubCategory>}
                          <PercentCategory>유사도 {data.accuracy}%</PercentCategory>
                        </CategoryWrap>
                        <div className="subtitle2">
                          {data.title.length <= 50
                            ? data.title
                            : data.title.slice(0, 50) + "..."}
                        </div>
                        <div className="caption">
                          {data.caption.length <= 60
                            ? data.caption
                            : data.caption.slice(0, 60) + "..."}
                        </div>
                      </StyledDataCard>
                    </button>
                  ))}
                </div>
              )}
              {paginationData && (
                <div className="pagination-container">
                  <Pagination
                    onClick={handlePaginationButtonClick}
                    meta={{
                      currentPage,
                      setCurrentPage,
                      page: paginationData.take,
                      totalPage: paginationData.totalPage,
                    }}
                  />
                </div>
              )}
              {isDetailModalOpen && (
                <DetailModal
                  caseId={caseId}
                  backAction={closeDetailModal}
                  isOpened={isDetailModalOpen}
                  closeModal={closeDetailModal}
                />
              )}
            </StyledDataCardContainer>
          </div>
        </StyledIssueContainerCard>
      )}
    </StyledDetailPage>
  );
}

const StyledDetailPage = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  background-color: ${colors.bg_con};
  min-height: 100vh;
  padding: 50px 0 20px 70px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .page-title {
    font-size: ${title38};
    font-weight: bold;
  }
`;

const StyledIssueContainerCard = styled.div`
  width: 900px;
  border-radius: 12px;
  background-color: white;
  ${boxShadow}
  padding: 30px;

  .title {
    width: 800px;
    font-size: ${title24};
    font-weight: 500;
    margin-bottom: 10px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
  }
  .subtitle {
    font-size: ${body14};
    color: ${colors.neutral_g4};
  }

  .top {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 10px;
    margin-bottom: 30px;
  }

  .issue-container {
    padding: 15px;
    background-color: ${colors.bg_con};
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
`;

const StyledDataCardContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 20px;
  gap: 20px;
  flex-wrap: wrap;
  background-color: ${colors.white};
  border-radius: 12px;

  a {
    width: 365px;
    text-decoration: none;
    color: ${colors.black};
  }

  .header-container {
    a {
      width: fit-content;
    }
    display: flex;
    align-items: center;
    width: 100%;
    position: left;
  }

  .header-title {
    font-size: 18px;
    margin-left: 10px;
  }

  .search-container {
    width: 100%;
    height: 100%;

    background-color: ${colors.bg_con};

    padding: 20px 30px;

    border-radius: 12px;
  }

  .search-header {
    display: flex;
    margin-bottom: 13px;
  }

  .search-title {
    ${body16}
    color: ${colors.primary_main};
    margin-right: 7px;
  }

  .search-content {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
  }

  .search-chip-title {
    width: 80px;

    margin-top: 5px;

    font-size: 16px;
    font-weight: 700;
    color: ${colors.primary_main};
  }

  .search-chip-container {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 700px;
  }

  .search-dropdown-icon {
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding-top: 5px;
  }

  .related-cases-container {
    button {
      border: none;
      background-color: transparent;
    }
    display: grid;
    grid-template-columns: 374px 374px;
    gap: 10px 15px;
  }
`;

const StyledDataCard = styled.div`
  border-radius: 12px;
  border: ${colors.neutral_g2} 1px solid;
  cursor: pointer;

  &:hover {
    border-radius: 12px;
    border: ${colors.primary_main} 1px solid;
  }

  flex-grow: 1;
  margin: 0 auto;
  padding: 20px;
  .subtitle2 {
    width: 334px;
    height: 48px;
    text-align: left;
    font-weight: bold;
    font-size: ${body16};
    margin-bottom: 10px;
  }
  .caption {
    width: 334px;
    height: 42px;
    text-align: left;
    font-size: ${body14};
    color: ${colors.neutral_g4};
  }
`;

const CategoryWrap = styled.div`
  display: flex;
  gap: 13px;
  margin-bottom: 13px;
`;

const PrimaryCategory = styled.div`
  color: ${colors.primary_main};
  background-color: ${colors.primary_sub};
  font-size: 14px;
  font-weight: 400;
  padding: 5px 9px;
  border-radius: 8px;
`;

const SubCategory = styled.div`
  color: ${colors.secondary_main};
  background-color: ${colors.secondary_sub};
  font-size: 14px;
  font-weight: 400;
  padding: 5px 9px;
  border-radius: 8px;
`;

const PercentCategory = styled.div`
  color: ${colors.secondary_main};
  background-color: ${colors.white};
  border: 1px solid ${colors.secondary_main};
  font-size: 14px;
  font-weight: 400;
  padding: 5px 9px;
  border-radius: 8px;
`;
