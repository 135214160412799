import React, { MouseEventHandler } from "react";
import styled from "styled-components";
import googleSvg from "@/assets/icons/36px/google.svg";
import IconButtonComponent from "../guides/button/IconButton";

const GoogleAuthenticationComponent: React.FC = () => {
  const client_id = import.meta.env.VITE_GOOGLE_CLIENT_KEY;
  const google_redirect_uri = import.meta.env.VITE_GOOGLE_REDIRECT_URI;

  const googleLogin: MouseEventHandler<HTMLDivElement> = () => {
    window.location.href = `https://accounts.google.com/o/oauth2/auth?client_id=${client_id}&redirect_uri=${google_redirect_uri}&response_type=code&scope=email+profile&access_type=offline`;
  };

  return (
    <StyledGoogleAuthenticationComponent>
      <IconButtonComponent
        action={googleLogin}
        text={"구글로 시작하기"}
        icon={<img src={googleSvg} alt="Google" />}
      />
    </StyledGoogleAuthenticationComponent>
  );
};

const StyledGoogleAuthenticationComponent = styled.div``;

export default GoogleAuthenticationComponent;
