import React, { useState } from "react";
import { colors } from "@/styles/color";
import styled from "styled-components";
import { boxShadow3 } from "@/styles/shadow";
import { body16 } from "@/styles/typography";
import DimmedModalComponent from "../guides/modal/DimmedModal";
import EnterCompanyPositionModal from "./EnterCompanyPositionModal";
import { postCompany } from "@/utils/company";

interface EnterCompanyModalProps {
  isOpened: boolean;
  closeModal: () => void;
  makeBlackText?: boolean;
  backAction: () => void;
  openCompanyModal: () => void;
  plan: "Personal" | "Team";
}

export default function EnterCompanyModal({
  isOpened,
  closeModal,
  makeBlackText = false,
  backAction,
  openCompanyModal,
  plan,
}: EnterCompanyModalProps) {
  const [isCompanyPositionModalOpen, setIsCompanyPositionModalOpen] = useState(false);
  const [hasError, setHasError] = useState("");
  const [formData, setFormData] = useState({
    companyName: "",
    plan,
    phoneNumber: "",
    address: "",
    registrationNumber: "",
  });

  const openCompanyPositionModal = () => {
    setIsCompanyPositionModalOpen(true);
  };

  const closeCompanyPositionModal = () => {
    setIsCompanyPositionModalOpen(false);
  };

  const backCompanyPositionModal = () => {
    setIsCompanyPositionModalOpen(() => false);
    openCompanyModal();
  };

  const formatPhoneNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,4})(\d{0,4})$/);
    if (match) {
      const part1 = match[1] ? match[1] : "";
      const part2 = match[2] ? "-" + match[2] : "";
      const part3 = match[3] ? "-" + match[3] : "";
      return `${part1}${part2}${part3}`;
    }
    return value;
  };

  const formatRegistrationNumber = (value: string) => {
    const cleaned = value.replace(/\D/g, "");
    const match = cleaned.match(/^(\d{0,3})(\d{0,2})(\d{0,5})$/);
    if (match) {
      const part1 = match[1] ? match[1] : "";
      const part2 = match[2] ? "-" + match[2] : "";
      const part3 = match[3] ? "-" + match[3] : "";
      return `${part1}${part2}${part3}`;
    }
    return value;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;

    setFormData((prevData) => ({ ...prevData, [id]: value }));
  };

  const handleClick = async () => {
    try {
      await postCompany({
        companyRequest: {
          ...formData,
          phoneNumber: formData.phoneNumber.replace(/-/g, ""),
          registrationNumber: formData.registrationNumber.replace(/-/g, ""),
        },
        userId: JSON.parse(localStorage.getItem("user")).id,
      });

      setFormData({
        companyName: "",
        plan,
        phoneNumber: "",
        address: "",
        registrationNumber: "",
      });

      setHasError("");

      openCompanyPositionModal();
    } catch (e) {
      setHasError(e.response.data.message);
    }
  };

  return (
    <>
      <DimmedModalComponent
        closeModal={closeModal}
        isOpened={isOpened}
        backAction={backAction}
        content={
          <EnterCompanyModalContent makeBlackText={makeBlackText} plan={plan}>
            <h1 className="title">{plan}</h1>
            <div className="command-wrap">
              <p>
                {plan === "Team"
                  ? "팀을 위한 최적의 선택"
                  : "핵심적인 기능만 간편하게 사용해보세요"}
              </p>
            </div>
            <div className="inputWrap">
              <label htmlFor="companyName">회사명</label>
              <input
                id="companyName"
                type="text"
                placeholder="회사 명을 입력해주세요"
                value={formData.companyName}
                onChange={handleChange}
              />
            </div>
            <div className="inputWrap">
              <label htmlFor="phoneNumber">연락처</label>
              <input
                id="phoneNumber"
                type="text"
                placeholder="담당자 연락처를 입력해주세요"
                maxLength={13}
                value={formatPhoneNumber(formData.phoneNumber)}
                onChange={handleChange}
              />
            </div>
            <div className="inputWrap">
              <label htmlFor="address">주소</label>
              <input
                id="address"
                type="text"
                placeholder="회사 주소를 입력해주세요"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div className="inputWrap">
              <label htmlFor="registrationNumber">사업자 번호</label>
              <input
                id="registrationNumber"
                type="text"
                placeholder="사업자 번호를 입력해주세요"
                maxLength={12}
                value={formatRegistrationNumber(formData.registrationNumber)}
                onChange={handleChange}
              />
            </div>
            <button onClick={handleClick}>서비스 이용하기</button>
            {hasError && <p className="error-message">{hasError}</p>}
          </EnterCompanyModalContent>
        }
      />

      {isCompanyPositionModalOpen && (
        <EnterCompanyPositionModal
          isOpened={isCompanyPositionModalOpen}
          closeModal={closeCompanyPositionModal}
          backAction={backCompanyPositionModal}
        />
      )}
    </>
  );
}

const EnterCompanyModalContent = styled.div<{
  makeBlackText?: boolean;
  plan: "Personal" | "Team";
}>`
  width: 530px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: ${(props) =>
      props.plan === "Team" ? colors.primary_main : colors.secondary_main};
  }

  .command-wrap {
    border-radius: 12px;
    background-color: ${colors.bg_con};
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }

  button {
    ${boxShadow3}
    border: none;
    background-color: ${colors.primary_main};
    border-radius: 12px;
    cursor: pointer;
    height: 47px;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: ${colors.white};
    margin: 0 auto;
  }

  .inputWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  label {
    font-size: 16px;
    font-weight: 500;
  }

  input {
    ${body16}
    color: ${(props) => (props.makeBlackText ? colors.black : colors.neutral_g3)};
    width: 100%;
    padding: 15px 12px 14px 12px;
    outline: none;
    border: 1px solid #eee;
    background-color: transparent;
    border-radius: 12px;
  }

  input:focus {
    color: ${colors.black};
  }

  input::placeholder {
    color: ${colors.neutral_g3};
  }

  .error-message {
    color: ${colors.secondary_main};
  }
`;
