import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "@/styles/color";
import { body16, title20 } from "@/styles/typography";
import TButton from "@/components/guides/button/TButton";
import { patchProjectName } from "@/utils/coreService";

interface ProjectRenameModalContentProps {
  projectId: string;
  projectName: string;
  onChangeName: () => void;
  onClose: () => void;
  handleModal: (isOpen: boolean) => void;
}

const ProjectRenameModalContent: React.FC<ProjectRenameModalContentProps> = ({
  projectId,
  onChangeName,
  onClose,
  handleModal,
}) => {
  const [projectName, setProjectName] = useState("");

  const handleClick = async () => {
    await patchProjectName({ projectId, newProjectName: projectName });

    await onChangeName();

    onClose();

    handleModal(false);
  };

  return (
    <StyledProjectRenameModalContent>
      <div className="modal-header">
        <h2>프로젝트 이름 변경</h2>
      </div>
      <div className="modal-body">
        <input
          type="text"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </div>
      <div className="modal-footer">
        <TButton
          text="저장"
          fontSize={body16}
          $bgcolor={colors.primary_main}
          $textcolor="white"
          onClick={handleClick}
        />
      </div>
    </StyledProjectRenameModalContent>
  );
};

const StyledProjectRenameModalContent = styled.div`
  z-index: 99;
  min-width: 370px;
  background: white;
  width: 530px;
  padding: 25px 15px;

  .modal-header {
    h2 {
      text-align: center;
      ${title20}
      margin: 0;
    }
    .close {
      cursor: pointer;
    }
  }

  .modal-body {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    input {
      width: 100%;
      padding: 10px;
      height: 47px;
      font-size: 16px;
      border: 1px solid ${colors.neutral_g2};
      border-radius: 8px;
      ${body16}
    }
  }

  .modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
`;

export default ProjectRenameModalContent;
