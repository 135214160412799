import { getGlobalScope } from "@sentry/react";
import { useEffect } from "react";

function SentryGlobal({ children }: { children: JSX.Element }) {
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    const sentryGlobalScope = getGlobalScope();

    sentryGlobalScope.setUser(user);
  });

  return <>{children}</>;
}

export default SentryGlobal;
