import React from "react";

const Delete: React.FC<{ color?: string }> = (props: { color?: string }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="ico">
        <g id="ico_2">
          <path
            id="&#236;&#132;&#160; 114"
            d="M5 13L13 5"
            stroke={props.color || "#3232C2"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            id="&#236;&#132;&#160; 115"
            d="M5 5L13 13"
            stroke={props.color || "#3232C2"}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default Delete;
