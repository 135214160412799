import React from "react";

const Check: React.FC<{ color?: string }> = (props: { color?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M13 6.5L7.1623 12.5L4 8.67014"
        stroke={props.color || "#3232C2"}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Check;
