import styled from "styled-components";
import DimmedModalWithCloseComponent from "../guides/modal/DimmedModalWithClose.tsx";
import { useEffect, useState } from "react";
import { getRelatedCaseDetailContents } from "@/utils/coreService.ts";
import SpinnerComponent from "../guides/spinner/Spinner.tsx";
import DataModalContentComponent from "../search/data/ParsedDataContent.tsx";

interface DetailModalProps {
  caseId: string;
  isOpened: boolean;
  closeModal: () => void;
  backAction: () => void;
}

export default function DetailModal({
  caseId,
  isOpened,
  closeModal,
  backAction,
}: DetailModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [relatedCaseDetailContents, setRelatedCaseDetailContents] = useState();

  useEffect(() => {
    setIsLoading((prev) => !prev);

    const fetchDetail = async () => {
      const { data } = await getRelatedCaseDetailContents({ caseId });

      setIsLoading((prev) => !prev);
      setRelatedCaseDetailContents(data);
    };

    fetchDetail();
  }, [caseId]);

  if (!relatedCaseDetailContents) {
    return;
  }

  if (isLoading) {
    return <SpinnerComponent />;
  }

  return (
    <>
      <DimmedModalWithCloseComponent
        isOpened={isOpened}
        closeModal={closeModal}
        backAction={backAction}
        content={
          <DetailModalContent>
            <DataModalContentComponent data={relatedCaseDetailContents} />
          </DetailModalContent>
        }
      />
    </>
  );
}

const DetailModalContent = styled.div`
  width: 847px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 669px;
  overflow-y: scroll;
`;
