import React from "react";

const LeftArrowComponent: React.FC<{ color?: string; size?: number }> = (props: {
  color?: string;
  size?: number;
}) => {
  return (
    <svg
      width={Number(props.size) || "18"}
      height={Number(props.size) || "18"}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="btn">
        <path
          id="ico"
          d="M11.5 14L6.5 9L11.5 4"
          stroke={props.color || "#AAAAAA"}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
};

export default LeftArrowComponent;
