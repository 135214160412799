import { colors } from "@/styles/color";
import { boxShadow } from "@/styles/shadow";
import { DropupCompoentProps } from "@/types/components/dropupProps";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import bottomArrow from "@/assets/icons/18px/bottomArrow.svg";
import upArrow from "@/assets/icons/18px/upArrow.svg";
import { useNavigate } from "react-router-dom";

const DropupComponent: React.FC<DropupCompoentProps> = ({ options, placeholder }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (option) => {
    setIsOpen(false);
    if (option.link === "/logout") {
      localStorage.removeItem("user");
      localStorage.removeItem("service-code");
      localStorage.removeItem("access_token");
      localStorage.removeItem("textarea-storage");

      navigate("/");
    } else if (option.link.startsWith("https://")) {
      window.location.href = option.link;
    } else if (option.link) {
      navigate(option.link);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <DropdownContainer ref={dropdownRef}>
      <DropdownButton onClick={() => setIsOpen(!isOpen)}>
        <div className="placeholder">{placeholder}</div>
        <div className="icon">
          <img src={!isOpen ? upArrow : bottomArrow} alt="메뉴 보기" width={18} />
        </div>
      </DropdownButton>
      {isOpen && (
        <DropdownContent>
          {options.map((option, index) => (
            <Option key={index} onClick={() => handleSelect(option)}>
              {option.icon && <img src={option.icon} alt="icon" />}
              {option.label}
            </Option>
          ))}
        </DropdownContent>
      )}
    </DropdownContainer>
  );
};
export default DropupComponent;

const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
`;

const DropdownButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 18px;
  height: 50px;
  background: white;
  border: 1px solid ${colors.neutral_g1};
  border-radius: 12px;
  cursor: pointer;
  .placeholder {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-grow: 1;
    padding-right: 18px;
  }
`;

const DropdownContent = styled.div`
  overflow: hidden;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid ${colors.neutral_g2};
  border-radius: 12px;
  margin-bottom: 16px;
  z-index: 1;
  ${boxShadow}
`;

const Option = styled.div`
  cursor: pointer;
  padding: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid ${colors.neutral_g2};

  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${colors.neutral_g2};
  }
`;
