import { css } from "styled-components";

// header

export const header10 = css`
  font-family: Pretendard;
  font-size: 10px;
  font-weight: 700;
  line-height: 12px;
`;

export const header11 = css`
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 700;
  line-height: 13.2px;
`;

export const header12 = css`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 700;
  line-height: 14.4px;
`;

export const header13 = css`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 700;
  line-height: 15.6px;
`;

export const header14 = css`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 700;
  line-height: 16.8px;
`;

export const header15 = css`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
`;

export const header16 = css`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 700;
  line-height: 19.2px;
`;

export const header17 = css`
  font-family: Pretendard;
  font-size: 17px;
  font-weight: 700;
  line-height: 20.4px;
`;

export const header18 = css`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.6px;
`;

export const header20 = css`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
`;

export const header21 = css`
  font-family: Pretendard;
  font-size: 21px;
  font-weight: 700;
  line-height: 25.2px;
`;

export const header22 = css`
  font-family: Pretendard;
  font-size: 22px;
  font-weight: 700;
  line-height: 26.4px;
`;

export const header23 = css`
  font-family: Pretendard;
  font-size: 23px;
  font-weight: 700;
  line-height: 27.6px;
`;

export const header24 = css`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
`;

export const header25 = css`
  font-family: Pretendard;
  font-size: 25px;
  font-weight: 700;
  line-height: 30px;
`;

export const header26 = css`
  font-family: Pretendard;
  font-size: 26px;
  font-weight: 700;
  line-height: 31.2px;
`;

export const header27 = css`
  font-family: Pretendard;
  font-size: 27px;
  font-weight: 700;
  line-height: 32.4px;
`;

export const header28 = css`
  font-family: Pretendard;
  font-size: 28px;
  font-weight: 700;
  line-height: 33.6px;
`;

export const header29 = css`
  font-family: Pretendard;
  font-size: 29px;
  font-weight: 700;
  line-height: 34.8px;
`;

export const header30 = css`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: 700;
  line-height: 36px;
`;

export const header31 = css`
  font-family: Pretendard;
  font-size: 31px;
  font-weight: 700;
  line-height: 37.2px;
`;

export const header32 = css`
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 700;
  line-height: 38.4px;
`;

export const header33 = css`
  font-family: Pretendard;
  font-size: 33px;
  font-weight: 700;
  line-height: 39.6px;
`;

export const header34 = css`
  font-family: Pretendard;
  font-size: 34px;
  font-weight: 700;
  line-height: 40.8px;
`;

export const header35 = css`
  font-family: Pretendard;
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
`;

export const header36 = css`
  font-family: Pretendard;
  font-size: 36px;
  font-weight: 700;
  line-height: 43.2px;
`;

export const header37 = css`
  font-family: Pretendard;
  font-size: 37px;
  font-weight: 700;
  line-height: 44.4px;
`;

export const header38 = css`
  font-family: Pretendard;
  font-size: 38px;
  font-weight: 700;
  line-height: 45.6px;
`;

export const header39 = css`
  font-family: Pretendard;
  font-size: 39px;
  font-weight: 700;
  line-height: 46.8px;
`;

export const header40 = css`
  font-family: Pretendard;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
`;

export const header41 = css`
  font-family: Pretendard;
  font-size: 41px;
  font-weight: 700;
  line-height: 49.2px;
`;

export const header42 = css`
  font-family: Pretendard;
  font-size: 42px;
  font-weight: 700;
  line-height: 50.4px;
`;

export const header43 = css`
  font-family: Pretendard;
  font-size: 43px;
  font-weight: 700;
  line-height: 51.6px;
`;

export const header44 = css`
  font-family: Pretendard;
  font-size: 44px;
  font-weight: 700;
  line-height: 52.8px;
`;

export const header45 = css`
  font-family: Pretendard;
  font-size: 45px;
  font-weight: 700;
  line-height: 54px;
`;

export const header46 = css`
  font-family: Pretendard;
  font-size: 46px;
  font-weight: 700;
  line-height: 55.2px;
`;

export const header47 = css`
  font-family: Pretendard;
  font-size: 47px;
  font-weight: 700;
  line-height: 56.4px;
`;

export const header48 = css`
  font-family: Pretendard;
  font-size: 48px;
  font-weight: 700;
  line-height: 57.6px;
`;

export const header50 = css`
  font-family: Pretendard;
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
`;
export const header52 = css`
  font-family: Pretendard;
  font-size: 52px;
  font-weight: 700;
  line-height: 62.4px;
`;

export const header54 = css`
  font-family: Pretendard;
  font-size: 54px;
  font-weight: 700;
  line-height: 64.8px;
`;

export const header58 = css`
  font-family: Pretendard;
  font-size: 58px;
  font-weight: 700;
  line-height: 69.8px;
`;

// title

export const title10 = css`
  font-family: Pretendard;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
`;

export const title11 = css`
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.2px;
`;

export const title12 = css`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.4px;
`;

export const title13 = css`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 500;
  line-height: 15.6px;
`;

export const title14 = css`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
`;

export const title15 = css`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
`;

export const title16 = css`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
`;

export const title17 = css`
  font-family: Pretendard;
  font-size: 17px;
  font-weight: 500;
  line-height: 20.4px;
`;

export const title18 = css`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
`;

export const title20 = css`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
`;

export const title21 = css`
  font-family: Pretendard;
  font-size: 21px;
  font-weight: 500;
  line-height: 25.2px;
`;

export const title22 = css`
  font-family: Pretendard;
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
`;

export const title23 = css`
  font-family: Pretendard;
  font-size: 23px;
  font-weight: 500;
  line-height: 27.6px;
`;

export const title24 = css`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
`;

export const title25 = css`
  font-family: Pretendard;
  font-size: 25px;
  font-weight: 500;
  line-height: 30px;
`;

export const title26 = css`
  font-family: Pretendard;
  font-size: 26px;
  font-weight: 500;
  line-height: 31.2px;
`;

export const title27 = css`
  font-family: Pretendard;
  font-size: 27px;
  font-weight: 500;
  line-height: 32.4px;
`;

export const title28 = css`
  font-family: Pretendard;
  font-size: 28px;
  font-weight: 500;
  line-height: 33.6px;
`;

export const title29 = css`
  font-family: Pretendard;
  font-size: 29px;
  font-weight: 500;
  line-height: 34.8px;
`;

export const title30 = css`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
`;

export const title31 = css`
  font-family: Pretendard;
  font-size: 31px;
  font-weight: 500;
  line-height: 37.2px;
`;

export const title32 = css`
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 500;
  line-height: 38.4px;
`;

export const title33 = css`
  font-family: Pretendard;
  font-size: 33px;
  font-weight: 500;
  line-height: 39.6px;
`;

export const title34 = css`
  font-family: Pretendard;
  font-size: 34px;
  font-weight: 500;
  line-height: 40.8px;
`;

export const title35 = css`
  font-family: Pretendard;
  font-size: 35px;
  font-weight: 500;
  line-height: 42px;
`;

export const title36 = css`
  font-family: Pretendard;
  font-size: 36px;
  font-weight: 500;
  line-height: 43.2px;
`;

export const title37 = css`
  font-family: Pretendard;
  font-size: 37px;
  font-weight: 500;
  line-height: 44.4px;
`;

export const title38 = css`
  font-family: Pretendard;
  font-size: 38px;
  font-weight: 500;
  line-height: 45.6px;
`;

export const title39 = css`
  font-family: Pretendard;
  font-size: 39px;
  font-weight: 500;
  line-height: 46.8px;
`;

export const title40 = css`
  font-family: Pretendard;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
`;

export const title41 = css`
  font-family: Pretendard;
  font-size: 41px;
  font-weight: 500;
  line-height: 49.2px;
`;

export const title42 = css`
  font-family: Pretendard;
  font-size: 42px;
  font-weight: 500;
  line-height: 50.4px;
`;

export const title43 = css`
  font-family: Pretendard;
  font-size: 43px;
  font-weight: 500;
  line-height: 51.6px;
`;

export const title44 = css`
  font-family: Pretendard;
  font-size: 44px;
  font-weight: 500;
  line-height: 52.8px;
`;

export const title45 = css`
  font-family: Pretendard;
  font-size: 45px;
  font-weight: 500;
  line-height: 54px;
`;

export const title46 = css`
  font-family: Pretendard;
  font-size: 46px;
  font-weight: 500;
  line-height: 55.2px;
`;

export const title47 = css`
  font-family: Pretendard;
  font-size: 47px;
  font-weight: 500;
  line-height: 56.4px;
`;

export const title48 = css`
  font-family: Pretendard;
  font-size: 48px;
  font-weight: 500;
  line-height: 57.6px;
`;

// body_400

export const body10 = css`
  font-family: Pretendard;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
`;

export const body11 = css`
  font-family: Pretendard;
  font-size: 11px;
  font-weight: 400;
  line-height: 13.2px;
`;

export const body12 = css`
  font-family: Pretendard;
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
`;

export const body13 = css`
  font-family: Pretendard;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
`;

export const body14 = css`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
`;

export const body15 = css`
  font-family: Pretendard;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
`;

export const body16 = css`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.2px;
`;

export const body17 = css`
  font-family: Pretendard;
  font-size: 17px;
  font-weight: 400;
  line-height: 20.4px;
`;

export const body18 = css`
  font-family: Pretendard;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
`;

export const body20 = css`
  font-family: Pretendard;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
`;

export const body21 = css`
  font-family: Pretendard;
  font-size: 21px;
  font-weight: 400;
  line-height: 25.2px;
`;

export const body22 = css`
  font-family: Pretendard;
  font-size: 22px;
  font-weight: 400;
  line-height: 26.4px;
`;

export const body23 = css`
  font-family: Pretendard;
  font-size: 23px;
  font-weight: 400;
  line-height: 27.6px;
`;

export const body24 = css`
  font-family: Pretendard;
  font-size: 24px;
  font-weight: 400;
  line-height: 28.8px;
`;

export const body25 = css`
  font-family: Pretendard;
  font-size: 25px;
  font-weight: 400;
  line-height: 30px;
`;

export const body26 = css`
  font-family: Pretendard;
  font-size: 26px;
  font-weight: 400;
  line-height: 31.2px;
`;

export const body27 = css`
  font-family: Pretendard;
  font-size: 27px;
  font-weight: 400;
  line-height: 32.4px;
`;

export const body28 = css`
  font-family: Pretendard;
  font-size: 28px;
  font-weight: 400;
  line-height: 33.6px;
`;

export const body29 = css`
  font-family: Pretendard;
  font-size: 29px;
  font-weight: 400;
  line-height: 34.8px;
`;

export const body30 = css`
  font-family: Pretendard;
  font-size: 30px;
  font-weight: 400;
  line-height: 36px;
`;

export const body31 = css`
  font-family: Pretendard;
  font-size: 31px;
  font-weight: 400;
  line-height: 37.2px;
`;

export const body32 = css`
  font-family: Pretendard;
  font-size: 32px;
  font-weight: 400;
  line-height: 38.4px;
`;

export const body33 = css`
  font-family: Pretendard;
  font-size: 33px;
  font-weight: 400;
  line-height: 39.6px;
`;

export const body34 = css`
  font-family: Pretendard;
  font-size: 34px;
  font-weight: 400;
  line-height: 40.8px;
`;

export const body35 = css`
  font-family: Pretendard;
  font-size: 35px;
  font-weight: 400;
  line-height: 42px;
`;

export const body36 = css`
  font-family: Pretendard;
  font-size: 36px;
  font-weight: 400;
  line-height: 43.2px;
`;

export const body37 = css`
  font-family: Pretendard;
  font-size: 37px;
  font-weight: 400;
  line-height: 44.4px;
`;

export const body38 = css`
  font-family: Pretendard;
  font-size: 38px;
  font-weight: 400;
  line-height: 45.6px;
`;

export const body39 = css`
  font-family: Pretendard;
  font-size: 39px;
  font-weight: 400;
  line-height: 46.8px;
`;

export const body40 = css`
  font-family: Pretendard;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
`;

export const body41 = css`
  font-family: Pretendard;
  font-size: 41px;
  font-weight: 400;
  line-height: 49.2px;
`;

export const body42 = css`
  font-family: Pretendard;
  font-size: 42px;
  font-weight: 400;
  line-height: 50.4px;
`;

export const body43 = css`
  font-family: Pretendard;
  font-size: 43px;
  font-weight: 400;
  line-height: 51.6px;
`;

export const body44 = css`
  font-family: Pretendard;
  font-size: 44px;
  font-weight: 400;
  line-height: 52.8px;
`;

export const body45 = css`
  font-family: Pretendard;
  font-size: 45px;
  font-weight: 400;
  line-height: 54px;
`;

export const body46 = css`
  font-family: Pretendard;
  font-size: 46px;
  font-weight: 400;
  line-height: 55.2px;
`;

export const body47 = css`
  font-family: Pretendard;
  font-size: 47px;
  font-weight: 400;
  line-height: 56.4px;
`;

export const body48 = css`
  font-family: Pretendard;
  font-size: 48px;
  font-weight: 400;
  line-height: 57.6px;
`;

// body_500

export const body14_500 = css`
  font-family: Pretendard;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
`;

export const body16_500 = css`
  font-family: Pretendard;
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
`