import React, { useState } from "react";
import { colors } from "@/styles/color";
import styled from "styled-components";
import { boxShadow3 } from "@/styles/shadow";
import { body16 } from "@/styles/typography";
import ButtonComponent from "../guides/button/Button";
import DimmedModalComponent from "../guides/modal/DimmedModal";
import EnterCompanyPositionModal from "./EnterCompanyPositionModal";
import { postverifyInvitationCode } from "@/utils/coreService";

interface EnterTeamModalProps {
  isOpened: boolean;
  closeModal: () => void;
  makeBlackText?: boolean;
  backAction: () => void;
  openTeamSimpleModal: () => void;
}

export default function EnterTeamSimpleModal({
  isOpened,
  closeModal,
  makeBlackText = false,
  backAction,
  openTeamSimpleModal,
}: EnterTeamModalProps) {
  const [isCompanyPositionModalOpen, setIsCompanyPositionModalOpen] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [teamCode, setTeamCode] = useState("");

  const openCompanyPositionModal = () => {
    setIsCompanyPositionModalOpen(true);
  };

  const closeCompanyPositionModal = () => {
    setIsCompanyPositionModalOpen(false);
  };

  const backCompanyPositionModal = () => {
    setIsCompanyPositionModalOpen(() => false);
    openTeamSimpleModal();
  };

  const handleConfirm = async () => {
    const userId = JSON.parse(localStorage.getItem("user")).id;

    try {
      await postverifyInvitationCode({
        verificationCode: teamCode,
        userId,
      });

      openCompanyPositionModal();
    } catch (error) {
      setHasError(true);
    }
  };

  return (
    <>
      <DimmedModalComponent
        closeModal={closeModal}
        isOpened={isOpened}
        backAction={backAction}
        content={
          <EnterTeamModalContent makeBlackText={makeBlackText}>
            <h1 className="title">팀 코드 입력</h1>
            <div className="command-wrap">
              <p>이메일로 초대받은 팀 코드를 입력하여 팀에 합류하세요</p>
            </div>
            <div className="inputWrap">
              <input
                id="team-code"
                type="text"
                placeholder="팀 코드를 입력해주세요"
                value={teamCode}
                onChange={(e) => setTeamCode(e.target.value)}
              />
            </div>
            {hasError && (
              <div className="error-message">팀 코드를 다시 확인해주세요.</div>
            )}
            <ButtonComponent text={"확인"} action={handleConfirm} />
          </EnterTeamModalContent>
        }
      />
      <EnterCompanyPositionModal
        isOpened={isCompanyPositionModalOpen}
        closeModal={closeCompanyPositionModal}
        backAction={backCompanyPositionModal}
      />
    </>
  );
}

const EnterTeamModalContent = styled.div<{ makeBlackText?: boolean }>`
  width: 530px;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
  }

  .command-wrap {
    border-radius: 12px;
    background-color: ${colors.bg_con};
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
      font-size: 12px;
      font-weight: 400;
    }
  }

  .inputWrap {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  input {
    ${body16}
    color: ${(props) => (props.makeBlackText ? colors.black : colors.neutral_g3)};
    width: 100%;
    padding: 15px 12px 14px 12px;
    outline: none;
    border: 1px solid #eee;
    background-color: transparent;
    border-radius: 12px;
  }

  input:focus {
    color: ${colors.black};
  }

  input::placeholder {
    color: ${colors.neutral_g3};
  }

  .error-message {
    color: red;
    font-size: 14px;
    font-weight: 500;
  }

  button {
    ${boxShadow3}
    border: none;
    background-color: ${colors.primary_main};
    border-radius: 12px;
    cursor: pointer;
    height: 47px;
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    color: ${colors.white};
    margin: 0 auto;
  }
`;
