import React from "react";
import { Route, Routes } from "react-router";

// authentication
import AuthenticationPage from "@/pages/authentication/Authentication";
import LoadingAuthenticationPage from "@/pages/authentication/LoadingAuthentication";

// error
import ErrorPage from "@/pages/error/Error";
import AgreementAuthenticationPage from "./pages/authentication/AgreementAuthentication";
import SearchPage from "./pages/search/SearchPage";
import ConsoleLayout from "./layouts/ConsoleLayout";
import RequireAuth from "./pages/RequireAuth";
import DetailPage from "./pages/search/DetailPage";
import { Redirect } from "./components/Redirect";
import * as Sentry from "@sentry/react";
import SentryGlobal from "./pages/SentryGlobal";

// const DynamicSettingsPage = () => {
//   const { setting } = useParams();

//   const componentMapping = {
//     profile: <ProfilePage />,
//     team: <TeamSettingPage />,
//     token: <TokenHistoryPage />,
//     payment: <PaymentPage />,
//     plan: <PlanPage />,
//   };

//   return componentMapping[setting] || <ProfilePage />;
// };

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const Router: React.FC = () => {
  return (
    <SentryRoutes>
      <Route path="/authentication" element={<AuthenticationPage />} />
      <Route path="/authentication/loading" element={<LoadingAuthenticationPage />} />
      <Route path="/authentication/agreement" element={<AgreementAuthenticationPage />} />
      {/* <Route path="/authentication/mobile" element={<MobileAuthenticationPage />} /> */}
      {/* <Route path="/authentication/enterip" element={<EnterIPAuthentication />} /> */}

      <Route
        path="/"
        element={
          <SentryGlobal>
            <Redirect to="/project" />
          </SentryGlobal>
        }
      />

      <Route
        path="/project"
        element={
          <RequireAuth>
            <SentryGlobal>
              <ConsoleLayout>
                <SearchPage />
              </ConsoleLayout>
            </SentryGlobal>
          </RequireAuth>
        }
      />

      <Route
        path="/project/:projectId"
        element={
          <RequireAuth>
            <SentryGlobal>
              <ConsoleLayout>
                <SearchPage />
              </ConsoleLayout>
            </SentryGlobal>
          </RequireAuth>
        }
      />

      <Route
        path="/project/:projectId/detail/:detailId"
        element={
          <RequireAuth>
            <SentryGlobal>
              <ConsoleLayout>
                <DetailPage />
              </ConsoleLayout>
            </SentryGlobal>
          </RequireAuth>
        }
      />

      {/* <Route
        path="/settings/:setting"
        element={
          <RequireAuth>
            <ConsoleLayout>
              <SettingsLayout>
                <DynamicSettingsPage />
              </SettingsLayout>
            </ConsoleLayout>
          </RequireAuth>
        }
      /> */}

      {/* <Route
        path="/service"
        element={
          <RequireAuth>
            <ConsoleLayout>
              <UserFeedbackPage />
            </ConsoleLayout>
          </RequireAuth>
        }
      /> */}

      <Route path="/error" element={<ErrorPage />} />
      <Route path="/*" element={<ErrorPage />} />
    </SentryRoutes>
  );
};
